import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import {setCurrentPageId, setIsLoading} from '../../store/app/events';
import {Image} from '../../components/Image';

import './Redirect.scss';
import {redirectsGraphQL} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';
import $appStore from '../../store/app/store';
import {useStore} from 'effector-react';

export const Redirect = () => {
  const {slug, category} = useParams();
  const appStore = useStore($appStore);

  const navigate = useNavigate();
  const [pageData, setPageData] = useState<any>();
  const [isRedirecting, setIsRedirecting] = useState(false);
  useEffect(() => {
    const getPage = async (path: string) => {
      const query = `{
  ${redirectsGraphQL.replace('#PATH#', path)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryRedirectsContents);
    };
    if (slug) {
      const path = category ? `${category}/${slug}` : `${slug}`;
      getPage(path);
    }
  }, [slug, category]);

  useEffect(() => {
    const path = category ? `${category}/${slug}` : `${slug}`;

    const redirectUrl = pageData && pageData.filter((item: any) => item.flatData.oldUrl === path);
    if (redirectUrl && redirectUrl[0]) {
      setIsRedirecting(true);
      setTimeout(() => {
        navigate(`/el/${redirectUrl[0].flatData.newUrl}`, {state: {status: 301}});
        setIsRedirecting(false);
      }, 2000);
    }
  }, [category, slug, pageData]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentPageId('notFound-page');
      setIsLoading(false);
    }, 100);
  }, []);

  return (
    <div className="notFound">
      {!isRedirecting ? (
        <>
          <h1>404</h1>
          <div className="notFound-text">Η σελίδα δεν βρέθηκε</div>
          <div className="notFound-image">
            <Image imageUrl={'freskoulis-svg.svg'} config="cache=14400" imageAlt="Η σελίδα δεν βρέθηκε" />
          </div>
        </>
      ) : (
        <>
          <h2>Η σελίδα άλλαξε</h2>
          <div className="notFound-text">Γινεται ανακατεύθυνση στην νέα σελίδα</div>
          <div className="loading-bar"></div>
        </>
      )}
    </div>
  );
};

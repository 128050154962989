import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import {FooterLegal} from './FooterLegal';
import {FooterNav} from './FooterNav';
import {Instagram} from './Instagram';
import {Prosferoume} from './Prosferoume';
import {SocialLinks} from './SocialLinks';
import {WeLoveSaladsTeaser} from './WeLoveSaladsTeaser';

import './Footer.scss';

interface IFooter {
  settings: any;
  footerNavData: any;
  legalNavData: any;
}
export const Footer = (props: IFooter) => {
  const {settings, footerNavData, legalNavData} = props;
  const appStore = useStore($appStore);
  return (
    <footer>
      {settings && footerNavData && legalNavData && (
        <div className="footerContainer">
          {settings.weLoveSaladsIsActive[appStore.language] && (
            <WeLoveSaladsTeaser
              title={settings.weLoveSaladsTitle[appStore.language]}
              url={settings.weLoveSaladsUrl.iv}
              text={settings.weLoveSaladsText[appStore.language]}
              link={settings.weLoveSaladsLink.iv}
              style={settings.weLoveSaladsStyle.iv}
            />
          )}
          {settings.instagramIsActive[appStore.language] && (
            <Instagram
              token={settings.instagramToken.iv}
              social={{
                title: settings.socialTitle[appStore.language],
                subtitle: settings.socialSubtitle[appStore.language],
                items: settings.socialItems,
              }}
            />
          )}
          {settings.prosferoumeIsActive[appStore.language] && (
            <Prosferoume
              title={settings.prosferoumeTitle[appStore.language]}
              subtitle={settings.prosferoumeSubtitle[appStore.language]}
              link={settings.prosferoumeLink[appStore.language]}
              url={settings.prosferoumeUrl.iv}
              image={settings.prosferoumeImage.iv[0].slug}
            />
          )}
          <SocialLinks
            title={settings.socialTitle[appStore.language]}
            subtitle={settings.socialSubtitle[appStore.language]}
            items={settings.socialItems}
          />
          <FooterNav data={footerNavData} />
          <FooterLegal data={legalNavData} legalText={settings.footerLegalText[appStore.language]} />
        </div>
      )}
    </footer>
  );
};

import {useStore} from 'effector-react';
import {useEffect} from 'react';

import {Image} from '../../../components/Image';
import useWindowSize from '../../../hooks/useWindowSize';
import {setModalProduct} from '../../../store/app/events';
import $appStore from '../../../store/app/store';

import './ModalProduct.scss';

export const ModalProduct = () => {
  const appStore = useStore($appStore);
  const {height} = useWindowSize();
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setModalProduct(null);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <div className={`modalProduct ${appStore.modalProduct ? 'isOpened' : ''}`} onClick={() => setModalProduct(null)}>
      {appStore.modalProduct && (
        <div className="modalProduct-content">
          <div className="modalProduct-content-inner">
            <div className="modalProduct-content-inner-image">
              <Image
                imageClass=""
                imageUrl={`${appStore.modalProduct.image}`}
                config={`height=500&cache=2630000`}
                imageWidth="auto"
                imageAlt={appStore.modalProduct.title}
              />
            </div>
            <div className="modalProduct-content-inner-text">
              <div className="modalProduct-content-inner-text-title">{appStore.modalProduct.title}</div>
              <div dangerouslySetInnerHTML={{__html: appStore.modalProduct.text}} />
            </div>
            <button className="modalProduct-content-inner-close">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {CONFIG, getContent} from '../../service/httpService';
import $appStore from '../../store/app/store';
import {SectionSlider, SectionWeLoveSalads, SectionParagwgoi, SectionTips, SectionNearaFylla} from './components';
import {homeGraphQL, slider1GraphQL, tipsGraphQL} from '../../service/graphQL';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
export const Home = () => {
  const appStore = useStore($appStore);
  const [homePageData, setHomePageData] = useState<any>();
  const [tipsData, setTipsData] = useState<any>();
  const [slider1Data, setSlider1Data] = useState<any>();

  useEffect(() => {
    const getHomePage = async () => {
      const query = `
   {
  ${homeGraphQL.replace('#CURRENT_LANGUAGE#', appStore.language)}
  ${slider1GraphQL.replace('#CURRENT_LANGUAGE#', appStore.language)}
  ${tipsGraphQL.replace('#CURRENT_LANGUAGE#', appStore.language)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setHomePageData(data.data.queryHomePageContents[0]);
      setSlider1Data(data.data.querySlider1Contents[0]);
      setTipsData(data.data.queryTipsContents);
      setCurrentPageId(data.data.queryHomePageContents[0].id);
      setTitle(data.data.queryHomePageContents[0].flatData.pageTItle);
      setDescription(data.data.queryHomePageContents[0].flatData.pageDescription);
      setLdJson(data.data.queryHomePageContents[0].flatData.ldJson);
    };
    getHomePage();
  }, [appStore.language]);

  useEffect(() => {
    if (homePageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [homePageData]);

  return (
    <div>
      {homePageData && (
        <div>
          <SectionSlider slider={homePageData.flatData.slider} slider1={slider1Data} />
          <SectionWeLoveSalads
            title={homePageData.flatData.weLoveSaladsTitle}
            link={homePageData.flatData.weLoveSaladsLink}
            url={homePageData.flatData.weLoveSaladsUrl[0]}
            linkText={homePageData.flatData.weLoveSaladsLinkText}
            products={homePageData.flatData.weLoveSaladsProducts}
          />

          <SectionParagwgoi
            title={homePageData.flatData.paragwgoiTitle}
            link={homePageData.flatData.paragwgoiLink}
            text={homePageData.flatData.paragwgoiText}
            url={homePageData.flatData.paragwgoiUrl[0]}
            image={homePageData.flatData.paragwgoiBackgroundImage[0].slug}
          />
          <SectionTips
            title={homePageData.flatData.tipsTitle}
            link={homePageData.flatData.tipsLink}
            url={homePageData.flatData.tipsUrl[0]}
            image={homePageData.flatData.tipsImage[0].slug}
            tips={tipsData}
          />

          <SectionNearaFylla
            title={homePageData.flatData.nearaTitle}
            subtitle={homePageData.flatData.nearaSubtitle}
            link={homePageData.flatData.nearaLink}
            url={homePageData.flatData.nearaFyllaUrl}
            linkText={homePageData.flatData.nearaLinkText}
            image={homePageData.flatData.nearaImage[0].slug}
          />
        </div>
      )}
    </div>
  );
};

import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Image} from '../../../components/Image';
import $appStore from '../../../store/app/store';
import {assetsPath, getUrl} from '../../../utils/utils';
import './FreskaLaxanika.scss';
interface IBio {
  title: string;
  subtitle: string;
  url: any;
  image: string;
  text: string;
}

export const FreskaLaxanika = (props: IBio) => {
  const {title, subtitle, image, url, text} = props;
  const appStore = useStore($appStore);

  useEffect(() => {
    url && getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl);
  }, [url]);

  return (
    <div className="categoriesFreskaLaxanika" style={{backgroundImage: `url(${assetsPath}/${image}`}}>
      <div className="categoriesFreskaLaxanika-inner">
        <Link
          to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
          className="categoriesFreskaLaxanika-inner-texts"
        >
          <div className="categoriesFreskaLaxanika-inner-texts-title">{title}</div>
          <div className="categoriesFreskaLaxanika-inner-texts-subtitle">{subtitle}</div>
          <div className="categoriesFreskaLaxanika-inner-texts-text">{text}</div>
          <div className="categoriesFreskaLaxanika-inner-texts-arrow">
            <Image imageUrl="arrow.svg" imageAlt={title} imageWidth={'30px'} config={'cache=2630000&width=30'} />
          </div>
        </Link>
      </div>
    </div>
  );
};

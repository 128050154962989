import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {assetsPath, getUrl, sanitizeUppercase} from '../../utils/utils';

import './MediaNav.scss';

interface IMediaNav {
  selected: string;
}

export const MediaNav = (props: IMediaNav) => {
  const {selected} = props;
  const appStore = useStore($appStore);
  return (
    <div className="mediaNav" style={{backgroundImage: `url(${assetsPath}/news.jpeg)`}}>
      <div className="mediaNav-inner">
        {appStore.mediaNavItems &&
          appStore.language &&
          appStore.mediaNavItems.map((item: any, index: number) => (
            <div
              className={`mediaNav-inner-item ${
                item.data.navLink.iv[0].data.friendlyUrl[appStore.language] === selected ? 'selected' : ''
              }`}
              key={index}
            >
              <Link
                to={getUrl(
                  appStore.language,
                  item.data.navLink.iv[0].__typename,
                  item.data.navLink.iv[0].data.friendlyUrl[appStore.language],
                )}
              >
                {sanitizeUppercase(item.data.navTitle[appStore.language])}
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

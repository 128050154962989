import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {$appStore} from '../../store/app/store';
import {parentCategoryGraphQL, subCategoryGraphQL} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
import {Hero} from './components/Hero';
import {Navigation} from './components/Navigation';
import {SubCategories} from './components/SubCategories';

import './Category.scss';
import {ProductList} from './components/ProductList';

interface ICategory {
  slug: string;
  id?: string;
  lang?: string;
}

export const Category = (props: ICategory) => {
  const {slug, id, lang} = props;
  const appStore = useStore($appStore);
  const [pageData, setPageData] = useState<any>();
  const [subCategories, setSubCategories] = useState<any>();
  const [pageId, setPageId] = useState<string>('');

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${parentCategoryGraphQL.replace('#LANG#', appStore.language).replace('#SLUG#', slug)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryCategoriesContents[0].flatData);

      setCurrentPageId(slug);
      setPageId(data.data.queryCategoriesContents[0].id);
      setTitle(data.data.queryCategoriesContents[0].flatData.pageTitle);
      setDescription(data.data.queryCategoriesContents[0].flatData.pageDescription);
      setLdJson(data.data.queryCategoriesContents[0].flatData.pageLdJson);
    };
    if (slug && appStore.language) {
      getPage(slug);
    }
  }, [appStore.language, slug]);

  useEffect(() => {
    const getSubCategories = async () => {
      const query = `{
  ${subCategoryGraphQL.replace('#PARENT_ID#', pageId)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setSubCategories(data.data.queryCategoriesContents);
    };
    if (pageData && pageId) {
      getSubCategories();
    }
  }, [pageData, pageId]);
  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);
  return (
    <div className="category">
      {appStore.language && pageData && subCategories && (
        <>
          {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
          <Hero
            backgroundImage={pageData.backgroundImage[0].slug}
            textBackground={pageData.textBackground}
            title={pageData.title}
            subtitle={pageData.subtitle}
            text={pageData.text}
            icon={pageData.icon && pageData.icon[0].flatData.icon[0].slug}
          />
          {/* {subCategories && <pre>{JSON.stringify(subCategories, null, 2)}</pre>} */}
          {subCategories && <Navigation subCategories={subCategories} parent={pageData.friendlyUrl} />}
          {pageData.products && pageData.products.length > 0 && <ProductList products={pageData.products} />}
          <SubCategories subCategories={subCategories} />
        </>
      )}

      {/* {subCategories && <pre>{JSON.stringify(subCategories, null, 2)}</pre>} */}
    </div>
  );
};

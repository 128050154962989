import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';

import {$appStore} from '../../store/app/store';
import {productGraphQl} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';
import {
  setCurrentPageId,
  setDescription,
  setIsLoading,
  setLdJson,
  setModalProduct,
  setTitle,
} from '../../store/app/events';

import {Divider} from '../../components/Divider/Divider';
import {Image} from '../../components/Image';

import {assetsPath, sanitizeUppercase} from '../../utils/utils';

import './Products.scss';

interface IProducts {
  slug: string;
  id?: string;
  lang?: string;
}

export const Products = (props: IProducts) => {
  const {slug, id} = props;
  const appStore = useStore($appStore);
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string, id: string) => {
      const query = `{
  ${productGraphQl.replace('#ID#', id).replace('#LANG#', appStore.language)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryProductsContents[0]);
      setTitle(data.data.queryProductsContents[0].flatData.pageTitle);
      setDescription(data.data.queryProductsContents[0].flatData.pageDescription);
      setLdJson(data.data.queryProductsContents[0].flatData.pageLdJson);
    };
    if (slug && id) getPage(slug, id);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) {
      setCurrentPageId(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);

  return (
    <div className="product">
      {pageData && (
        <>
          <h1 className="" style={{backgroundImage: `url(${assetsPath}/title-line-single.svg?cache=2630000)`}}>
            <span>{pageData.flatData.title}</span>
          </h1>

          <h2>{pageData.flatData.categoryTitle}</h2>
          <div className="product-details">
            <div className="product-details-image">
              {pageData.flatData.iconDetailPage && pageData.flatData.iconDetailPage.length > 0 && (
                <img
                  className="product-details-image-icon"
                  src={`${assetsPath}/${pageData.flatData.iconDetailPage[0].slug}`}
                  alt={pageData.flatData.title}
                />
              )}
              <Image
                imageUrl={pageData.flatData.image[0].slug}
                config="width=500&height=500&mode=max&cache=2630000"
                imageAlt={pageData.flatData.title}
              />
            </div>
            <div className="product-details-textsContainer">
              {pageData.flatData.ingredientsText && (
                <div className="product-details-textsContainer-ingredients">
                  <div className="product-details-textsContainer-ingredients-title">
                    {pageData.flatData.ingredientsTitle}
                  </div>
                  <div className="product-details-textsContainer-ingredients-text">
                    {pageData.flatData.ingredientsText}
                  </div>
                </div>
              )}
              {pageData.flatData.temperature && (
                <div
                  className="product-details-textsContainer-temperature"
                  dangerouslySetInnerHTML={{__html: pageData.flatData.temperature}}
                />
              )}
              {(pageData.flatData.weightText || pageData.flatData.portions) && (
                <div className="product-details-textsContainer-weightPortions">
                  {pageData.flatData.weightText && (
                    <>
                      <div className="product-details-textsContainer-weightPortions-title">
                        {pageData.flatData.weightTitle}:
                      </div>

                      <div className="product-details-textsContainer-weightPortions-text">
                        {pageData.flatData.weightText}
                      </div>
                    </>
                  )}
                  {pageData.flatData.weightText && pageData.flatData.portions && (
                    <div className="product-details-textsContainer-weightPortions-separator"></div>
                  )}
                  <div className="product-details-textsContainer-weightPortions-portions">
                    {pageData.flatData.portions}
                  </div>
                </div>
              )}
              {(pageData.flatData.infoTitle ||
                pageData.flatData.infoText1 ||
                pageData.flatData.infoText2 ||
                pageData.flatData.infoText3) && (
                <div className="product-details-textsContainer-textGroup">
                  <div className="product-details-textsContainer-textGroup-title">{pageData.flatData.infoTitle}</div>

                  <div
                    className="product-details-textsContainer-textGroup-text1"
                    dangerouslySetInnerHTML={{__html: pageData.flatData.infoText1}}
                  />
                  <div
                    className="product-details-textsContainer-textGroup-text2"
                    dangerouslySetInnerHTML={{__html: pageData.flatData.infoText2}}
                  />
                  <div
                    className="product-details-textsContainer-textGroup-text3"
                    dangerouslySetInnerHTML={{__html: pageData.flatData.infoText3}}
                  />
                </div>
              )}
              {pageData.flatData.nutritionTable && (
                <div
                  className="product-details-textsContainer-nutritionButton"
                  onClick={() =>
                    setModalProduct({
                      image: pageData.flatData.image[0].slug,
                      title: pageData.flatData.title,
                      text: pageData.flatData.nutritionTable,
                    })
                  }
                >
                  {appStore.language === 'el' ? 'Διατροφικός πίνακας' : 'Nutritional table'}
                </div>
              )}
            </div>
          </div>
          <Divider />
          <div className="product-content">
            {pageData.flatData.icons && pageData.flatData.icons.length > 0 && (
              <div className="product-content-icons">
                {pageData.flatData.icons.map((icon: any, index: number) => (
                  <div className="product-content-icons-item" key={index}>
                    <div className="product-content-icons-item-image">
                      <Image imageUrl={icon.flatData.icon[0].slug} imageAlt={icon.flatData.title} />
                    </div>
                    <div className="product-content-icons-item-title">
                      {icon.flatData.title && sanitizeUppercase(icon.flatData.title)}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {pageData.referencingCategoriesContents[0].flatData.enableProductIngredients && (
              <div className="product-content-ingredients">
                {pageData.flatData.ingredientsImage && (
                  <div className="product-content-ingredients-image">
                    <Image
                      imageUrl={pageData.flatData.ingredientsImage[0].slug}
                      imageAlt={`${pageData.flatData.title}: ${pageData.flatData.ingredientsTitle}`}
                    />
                    <div className="product-content-ingredients-image-freskoulis">
                      <Image
                        imageUrl={'freskoulis-svg.svg'}
                        config="cache=14400"
                        imageAlt={`${pageData.flatData.title}: ${pageData.flatData.ingredientsTitle}`}
                      />
                    </div>
                  </div>
                )}

                {pageData.flatData.ingredients && pageData.flatData.ingredients.length > 0 && (
                  <div className={`product-content-ingredients-container`}>
                    <div className={`product-content-ingredients-items items-${pageData.flatData.ingredients.length}`}>
                      {pageData.flatData.ingredients.map((icon: any, index: number) => (
                        <div className="product-content-ingredients-items-item" key={index}>
                          <div className="product-content-ingredients-items-item-image">
                            <Image
                              imageUrl={icon.flatData.image[0].slug}
                              imageWidth=""
                              imageAlt={icon.flatData.title}
                              config="width=200&height=200&cache=11440"
                            />
                          </div>
                          <div className="product-content-ingredients-items-item-title">
                            {icon.flatData.title && sanitizeUppercase(icon.flatData.title)}
                          </div>
                          <div className="item-dividerBackground"></div>
                          <div className="item-divider item-shadow"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {pageData.referencingCategoriesContents[0].flatData.enableProductRecipes &&
            pageData.flatData.recipesList &&
            pageData.flatData.recipesList.length > 0 && (
              <div className="product-recipes">
                <div className="product-recipes-title">{pageData.flatData.recipesTitle}</div>
                <div className="product-recipes-subtitle">{pageData.flatData.recipesSubtitle}</div>
                <div className="product-recipes-items">
                  {pageData.flatData.recipesList.map((item: any, index: number) => (
                    <a
                      href={item.flatData.recipeLink}
                      target="_blank"
                      rel="noreferrer"
                      className="product-recipes-items-item"
                      key={index}
                    >
                      <div className="product-recipes-items-item-image">
                        <Image imageUrl={item.flatData.recipeImage[0].slug} imageAlt={item.flatData.recipeTitle} />
                      </div>
                      <div className="product-recipes-items-item-title">{item.flatData.recipeTitle}</div>
                      <div className="item-dividerBackground"></div>
                      <div className="item-divider item-shadow"></div>
                    </a>
                  ))}
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

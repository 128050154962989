import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Image} from '../../../components/Image';
import $appStore from '../../../store/app/store';
import {assetsPath, getUrl} from '../../../utils/utils';
import './Snack.scss';
interface ISnack {
  title: string;
  subtitle: string;
  url: any;
  images: any;
  text: string;
  backgroundColor: string;
}

export const Snack = (props: ISnack) => {
  const {title, subtitle, images, url, text, backgroundColor} = props;
  const appStore = useStore($appStore);

  useEffect(() => {
    url && getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl);
  }, [url]);

  return (
    <div className="categoriesSnack" style={{backgroundColor: backgroundColor}}>
      <div className="categoriesSnack-inner">
        {/* <Link to={getUrl(url[0].__typename, url[0].flatData.friendlyUrl)} className="categoriesSnack-inner-image">
          <Image imageUrl={image} imageClass={''} imageAlt={title} config={'width=1000'} />
        </Link> */}
        <Link
          to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
          className="categoriesSnack-inner-topTexts"
        >
          <div className="categoriesSnack-inner-topTexts-title">{title}</div>
          <div className="categoriesSnack-inner-topTexts-subtitle">{subtitle}</div>
        </Link>
        <div
          className="categoriesSnack-inner-snack-bar"
          style={{backgroundImage: `url(${assetsPath}/snack-img-bg.png)`}}
        >
          <div className="snacks-inner">
            {images &&
              images.map((image: any, index: number) => (
                <div className="snack-ph" key={index}>
                  <div className="bg" style={{backgroundImage: `url(${assetsPath}/${image.slug}`}}>
                    <img className="bg-img" src={`${assetsPath}/${image.slug}`} alt="" />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Link
          to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
          className="categoriesSnack-inner-bottomTexts"
        >
          <div className="categoriesSnack-inner-bottomTexts-text" dangerouslySetInnerHTML={{__html: text}} />
          <div className="categoriesSnack-inner-bottomTexts-arrow">
            <Image imageUrl="arrow-white.svg" imageAlt={title} config={'cache=2630000'} />
          </div>
        </Link>
      </div>
    </div>
  );
};

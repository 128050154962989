import {AppDomain} from './domain';
import {Language} from './types';

export const setLanguage = AppDomain.createEffect<Language, Language>();
export const setPageRoutes = AppDomain.createEvent<any>();
export const setNavIsOpen = AppDomain.createEvent<boolean>();
export const setIsMobile = AppDomain.createEvent<boolean>();
export const setIsLoading = AppDomain.createEvent<boolean>();
export const setLanguages = AppDomain.createEvent<any>();
export const setMediaNavItems = AppDomain.createEvent<any>();
export const setCurrentPageId = AppDomain.createEvent<string>();
export const setTitle = AppDomain.createEvent<string>();
export const setNutritionText = AppDomain.createEvent<string>();
export const setDescription = AppDomain.createEvent<string>();
export const setLdJson = AppDomain.createEvent<string>();
export const setGtm = AppDomain.createEvent<string>();
export const setModalImage = AppDomain.createEvent<string | null>();
export const setModalProduct = AppDomain.createEvent<any>();
export const setEnableEnglish = AppDomain.createEvent<boolean>();

import './FooterBand.scss';
interface IFooter {
  text: string;
  className?: string;
}
export const FooterBand = (props: IFooter) => {
  const {text, className} = props;
  return (
    <div className="footerBand">
      <div className={`text ${className}`} dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
};

import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {getUrl} from '../../../utils/utils';
import {Image} from '../../../components/Image';
import $appStore from '../../../store/app/store';
import {useStore} from 'effector-react';
import './Hero.scss';
interface IHero {
  title: string;
  subtitle: string;
  image: string;
  backgroundImage: string;
  url: any;
}
export const Hero = (props: IHero) => {
  const {title, subtitle, image, backgroundImage, url} = props;
  const appStore = useStore($appStore);

  useEffect(() => {
    url && getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl);
  }, [url]);
  return (
    <div className="categoriesHero">
      <div className="categoriesHero-banner">
        <Image imageUrl={backgroundImage} imageClass={'scale'} imageAlt={''} config={''} />
      </div>
      <div className="categoriesHero-overlay">
        <Link
          to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
          className="categoriesHero-overlay-inner"
        >
          <div className="categoriesHero-overlay-inner-texts">
            <div className="categoriesHero-overlay-inner-texts-title">{title}</div>
            <div className="categoriesHero-overlay-inner-texts-subtitle">{subtitle}</div>
          </div>
          <div className="categoriesHero-overlay-inner-image">
            <Image imageUrl={image} imageClass={''} imageAlt={''} config={''} />
          </div>
        </Link>
      </div>
    </div>
  );
};

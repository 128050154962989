import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {useLocation} from 'react-router-dom';
import {$appStore} from '../../store/app/store';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
import {MediaNav} from '../../components/MediaNav/MediaNav';
import {Image} from '../../components/Image/Image';

import {CONFIG, getContent} from '../../service/httpService';
import {articlesDetailsGraphQl} from '../../service/graphQL';
import {assetsPath} from '../../utils/utils';

import './NewsDetails.scss';
interface INewsDetails {
  slug: string;
  id?: string;
  lang?: string;
}

export const NewsDetails = (props: INewsDetails) => {
  const {slug, id, lang} = props;
  const appStore = useStore($appStore);
  const location = useLocation();
  const [page, setPage] = useState<any>();
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string, id: string) => {
      const query = `{
  ${articlesDetailsGraphQl.replace('#ID#', id)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryArticlesContents[0]);
      setTitle(data.data.queryArticlesContents[0].flatData.pageTitle);
      setDescription(data.data.queryArticlesContents[0].flatData.pageDescription);
      setLdJson(data.data.queryArticlesContents[0].flatData.pageLdJson);
    };
    if (slug && id) getPage(slug, id);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) {
      setCurrentPageId(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);
  return (
    <div className="newsDetails">
      <MediaNav selected="ta-nea-mas" />
      {pageData && (
        <div className="newsDetails-inner">
          <div className="newsDetails-inner-top">
            <div className="newsDetails-inner-top-title">{pageData.flatData.articleTitle}</div>
            <div className="newsDetails-inner-top-subtitle">{pageData.flatData.artcleSubtitle}</div>
          </div>
          {pageData.flatData.articleImage.length > 0 && (
            <div
              className="newsDetails-inner-image"
              style={{
                backgroundImage: `url(${assetsPath}/${pageData.flatData.articleImage[0].slug}?width=1280&cache=2630000`,
              }}
            />
          )}
          <div className="newsDetails-inner-content">
            {pageData.flatData.sections.map((item: any, index: number) => (
              <div className="newsDetails-inner-content-item" key={index}>
                {item.text && (
                  <div className="newsDetails-inner-content-item-text" dangerouslySetInnerHTML={{__html: item.text}} />
                )}
                {item.image &&
                  item.image.map((image: any, index: number) => (
                    <Image
                      imageClass="newsDetails-inner-content-item-image"
                      key={index}
                      imageUrl={`${image.slug}`}
                      imageAlt={pageData.flatData.articleTitle}
                      imageWidth={'auto'}
                    />
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

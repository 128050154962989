import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import packageInfo from '../../package.json';

const useCacheBuster = () => {
  const location = useLocation();
  const parseVersion = (str: string) => +str.replace(/\D/g, '');

  useEffect(() => {
    axios(`/meta.json?v=${+new Date()}`)
      // .then((response) => {
      //   response.json();
      //   console.log(response.json());
      // })
      .then((meta: any) => {
        if (meta.data.version) {
          const metaVersion = parseVersion(meta.data.version);
          const packageVersion = parseVersion(packageInfo.version);
          if (packageVersion < metaVersion) {
            console.log('new version found');
            if (window?.location?.reload) {
              console.log('reloading...');
              window.location.reload();
            }
          }
        }
      })
      .catch((error) => {
        console.error('something went wrong fetching meta.json', error);
      });
  }, [location]);

  return null;
};

export default useCacheBuster;

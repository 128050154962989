import React, {useState} from 'react';
import axios from 'axios';
import {Formik, Form, Field} from 'formik';

import {label} from '../../utils/getLabel';
import $appStore from '../../store/app/store';
import {useStore} from 'effector-react';

import './ContactForm.scss';
export const ContactForm = () => {
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const appStore = useStore($appStore);

  const handleValidation = (values: any) => {
    let errors: any = {};
    //console.log(values);
    for (const key in values) {
      const value = `${values[key]}`;
      if (value === '') {
        errors[key] = label('mandatory', appStore.language);
      }
    }
    return errors;
  };

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    alert(1);
    const url = `https://cms.greymatter.gr/api/content/freskoulis/contact/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', 'freskoulis:default');
    params.append('client_secret', 't0pfiie8qxxliakbejzzbdchjihseowrfpbx2bmqwrox');
    params.append('scope', 'squidex-api');

    axios({
      method: 'post',
      url: 'https://cms.greymatter.gr/identity-server/connect/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
      },
      data: params,
    })
      .then((response) => {
        const token = response.data.access_token;
        axios
          .post(url, values, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setSubmitting(false);
            if (response) {
              resetForm();
              setIsFormSuccess(true);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="contactForm">
      {isFormSuccess && (
        <div className="ContactForm-success">
          <div className="text">{label('messageSent', appStore.language)}</div>
        </div>
      )}
      {!isFormSuccess && (
        <Formik
          initialValues={{fullName: '', phone: '', email: '', subject: '', message: ''}}
          validate={(values) => {
            return handleValidation(values);
          }}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            onSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({errors, touched, handleChange, isSubmitting}) => (
            <Form id="contact-form" className="form -pt2">
              <div className="form-fields">
                <div className="form-row">
                  <div className={`form-control ${touched.fullName && errors.fullName ? 'has-error' : ''}`}>
                    <label htmlFor="firstName" className="form-label form-label-outside">
                      {label('fullName', appStore.language)}
                    </label>
                    <Field
                      type="text"
                      className="form-input"
                      name="fullName['iv']"
                      id="firstName"
                      placeholder={label('fullName', appStore.language)}
                    />
                    {touched.fullName && errors.fullName && <p className="error-message">{errors.fullName}</p>}
                  </div>
                </div>
                <div className="form-row">
                  <div className={`form-control ${touched.email && errors.email ? 'has-error' : ''}`}>
                    <label htmlFor="email" className="form-label form-label-outside">
                      EMAIL
                    </label>
                    <Field type="email" name="email['iv']" id="email" placeholder="Email" className="form-input" />
                    {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                  </div>
                </div>

                <div className="form-row">
                  <div className={`form-control ${touched.phone && errors.phone ? 'has-error' : ''}`}>
                    <label htmlFor="phone" className="form-label form-label-outside">
                      {label('phone', appStore.language)}
                    </label>
                    <Field type="text" name="phone['iv']" id="phone" placeholder="Τηλέφωνο" className="form-input" />
                    {touched.phone && errors.phone && <p className="error-message">{errors.phone}</p>}
                  </div>
                </div>

                <div className="form-row">
                  <div className={`form-control ${touched.subject && errors.subject ? 'has-error' : ''}`}>
                    <label htmlFor="subject" className="form-label form-label-outside">
                      {label('subject', appStore.language)}
                    </label>
                    <Field type="text" name="subject['iv']" id="subject" placeholder="Θέμα" className="form-input" />
                    {touched.subject && errors.subject && <p className="error-message">{errors.subject}</p>}
                  </div>
                </div>

                <div className="form-row">
                  <div className={`form-control ${touched.message && errors.message ? 'has-error' : ''}`}>
                    <label htmlFor="message" className="form-label form-label-outside">
                      {label('message', appStore.language)}
                    </label>
                    <Field as="textarea" name="message['iv']" id="message" className="form-input" />
                    {touched.message && errors.message && <p className="error-message --textarea">{errors.message}</p>}
                  </div>
                </div>
              </div>

              <div className="form-action">
                <button className="button --primary" type="submit" disabled={isSubmitting}>
                  <span className="text">{label('submit', appStore.language)}</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

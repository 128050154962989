import React from 'react';
import {Image} from '../Image';
import useWindowSize from '../../hooks/useWindowSize';
import './HeaderImage.scss';
interface IHeaderImage {
  image: string;
  title: string;
  className?: string;
}

export const HeaderImage = (props: IHeaderImage) => {
  const {image, className, title} = props;
  const {width} = useWindowSize();

  return (
    <div className={`HeaderImage ${className}`}>
      <Image imageUrl={image} config={`width=${width}&cache=2630000`} imageAlt={title} />
    </div>
  );
};

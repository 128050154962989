import {Image} from '../../Image';
import {SetLink} from '../../SetLink/SetLink';

import './SocialLinks.scss';

interface ISocialLinks {
  title: string;
  subtitle: string;
  items: any;
}
export const SocialLinks = (props: ISocialLinks) => {
  const {title, subtitle, items} = props;
  return (
    <div className="social-links">
      <div className="follow">
        <strong>{title}</strong> {subtitle}
      </div>
      <div className="social">
        {items?.iv.map((item: any, index: number) => (
          <SetLink
            className="social-icon"
            key={index}
            link={item.itemUrl}
            title={item.title}
            friendlyUrl={item[0]?.data.friendlyUrl}
          >
            <Image imageUrl={item.itemImage[0].slug} config="" imageAlt={item.itemTitle} />
          </SetLink>
        ))}
      </div>
    </div>
  );
};

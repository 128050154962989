import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Image} from '../../../components/Image';
import $appStore from '../../../store/app/store';
import {assetsPath, getUrl} from '../../../utils/utils';
import './Ellhnikhs.scss';
interface IEllhnikhs {
  title: string;
  subtitle: string;
  url: any;
  products: any;
  image: string;
}

export const Ellhnikhs = (props: IEllhnikhs) => {
  const {title, subtitle, products, url, image} = props;
  const appStore = useStore($appStore);

  useEffect(() => {
    url && getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl);
  }, [url]);

  return (
    <div className="categoriesEllinikis" style={{backgroundImage: `url(${assetsPath}/${image})`}}>
      <Link
        to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
        className="categoriesEllinikis-inner"
      >
        <div className="categoriesEllinikis-inner-title">{title}</div>
        <div className="categoriesEllinikis-inner-subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
        <div className="categoriesEllinikis-inner-arrow">
          <Image imageUrl="arrow-white.svg" imageAlt={title} imageWidth={'30px'} config={'cache=2630000&width=30'} />
        </div>
      </Link>
      <div className="categoriesEllinikis-products">
        {products.map((item: any, index: number) => (
          <div className="categoriesEllinikis-products-item" key={index}>
            <Link to={getUrl(appStore.language, 'products', item.flatData.friendlyUrl)}>
              <Image
                imageUrl={item.flatData.image[0].slug}
                imageAlt={item.flatData.title}
                config={'cache=2630000&width=300&mode=max'}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

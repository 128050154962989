import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {useLocation, useParams} from 'react-router-dom';
import {$appStore} from '../../store/app/store';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
import {CONFIG, getContent} from '../../service/httpService';
import {downloadDetailsGraphQl} from '../../service/graphQL';
import {sanitizeUppercase} from '../../utils/utils';
import {DownloadImage} from '../Downloads/components/DownloadImage';
import {DownloadCatalogue} from '../Downloads/components/DownloadCatalogue';
import {MediaNav} from '../../components/MediaNav/MediaNav';

interface IDownloadCategories {
  slug: string;
  id: string;
}

export const DownloadCategories = (props: IDownloadCategories) => {
  const {slug, id} = props;
  const appStore = useStore($appStore);
  // const {slug, lang} = useParams();
  const [pageData, setPageData] = useState<any>();
  const [downloadType, setDownloadType] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string, id: string) => {
      const query = `{
  ${downloadDetailsGraphQl.replace('#ID#', id)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryDownloaditemsContents);
      setDownloadType(
        data.data.queryDownloaditemsContents &&
          data.data.queryDownloaditemsContents[0].flatData.downloadType.toLowerCase(),
      );
      setTitle(data.data.queryDownloaditemsContents[0].flatData.pageTitle);
      setDescription(data.data.queryDownloaditemsContents[0].flatData.pageDescription);
      setLdJson(data.data.queryDownloaditemsContents[0].flatData.pageLdJson);
    };
    if (slug) getPage(slug, id);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) setCurrentPageId(slug);
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);

  const setComponent = (item: any, type: string, index: number) => {
    if (type === 'catalogue') {
      return <DownloadCatalogue item={item} key={index} />;
    }
    if (type === 'image') {
      return <DownloadImage item={item} key={index} />;
    }
  };

  return (
    <div className="downloads">
      <MediaNav selected={'downloads'} />
      {pageData && pageData[0] && downloadType && (
        <div className={`downloads-${downloadType && downloadType}`}>
          <div className={`downloads-${downloadType}-section`}>
            <div className={`downloads-${downloadType}-section-title`}>
              {sanitizeUppercase(pageData[0].flatData.title)}
            </div>
            <div className={`downloads-${downloadType}-section-subtitle`}>{pageData[0].flatData.subtitle}</div>
            <div className={`downloads-${downloadType}-section-list`}>
              {pageData[0].flatData.items.map((item: any, index: number) => setComponent(item, downloadType, index))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import {AppState} from './types';
import {AppDomain} from './domain';
import {
  setLanguage,
  setLanguages,
  setNavIsOpen,
  setCurrentPageId,
  setPageRoutes,
  setIsLoading,
  setTitle,
  setDescription,
  setLdJson,
  setMediaNavItems,
  setModalImage,
  setNutritionText,
  setIsMobile,
  setModalProduct,
  setGtm,
  setEnableEnglish,
} from './events';
const localLanguage = localStorage.getItem('language');
const initialState: AppState = {
  languages: [],
  language: localLanguage !== null ? localLanguage : 'el',
  navIsOpen: false,
  currentPageId: null,
  pageRoutes: [],
  isLoading: false,
  siteTitle: '',
  siteDescription: '',
  siteLdJson: '',
  mediaNavItems: null,
  modalImage: null,
  nutritionText: null,
  isMobile: false,
  modalProduct: null,
  gtm: '',
  enableEnglish: false,
};
export const $appStore = AppDomain.store<AppState>(initialState)
  .on(setGtm, (state, gtm) => {
    return {
      ...state,
      gtm: gtm,
    };
  })
  .on(setEnableEnglish, (state, value) => {
    return {
      ...state,
      enableEnglish: value,
    };
  })
  .on(setLanguage, (state, language) => {
    return {
      ...state,
      language: language,
    };
  })
  .on(setIsMobile, (state, value) => {
    return {
      ...state,
      isMobile: value,
    };
  })
  .on(setModalImage, (state, value) => {
    return {
      ...state,
      modalImage: value,
    };
  })
  .on(setModalProduct, (state, value) => {
    return {
      ...state,
      modalProduct: value,
    };
  })
  .on(setPageRoutes, (state, pageRoutes) => {
    return {
      ...state,
      pageRoutes: pageRoutes,
    };
  })
  .on(setCurrentPageId, (state, setCurrentPageId) => {
    return {
      ...state,
      currentPageId: setCurrentPageId,
    };
  })
  .on(setNavIsOpen, (state, value) => {
    return {
      ...state,
      navIsOpen: value,
    };
  })
  .on(setIsLoading, (state, value) => {
    return {
      ...state,
      isLoading: value,
    };
  })
  .on(setLanguages, (state, languages) => {
    return {
      ...state,
      languages: languages,
    };
  })
  .on(setMediaNavItems, (state, items) => {
    return {
      ...state,
      mediaNavItems: items,
    };
  })

  .on(setTitle, (state, value) => {
    return {
      ...state,
      siteTitle: value,
    };
  })
  .on(setDescription, (state, value) => {
    return {
      ...state,
      siteDescription: value,
    };
  })
  .on(setNutritionText, (state, value) => {
    return {
      ...state,
      nutritionText: value,
    };
  })
  .on(setLdJson, (state, value) => {
    return {
      ...state,
      siteLdJson: value,
    };
  });

$appStore.watch((v) => {
  // console.log(v);
  if (!v.enableEnglish && v.language === 'en') {
    localStorage.setItem('language', 'en');
    setLanguage('el');
  }
});

export default $appStore;

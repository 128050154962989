import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';

interface ISetLink {
  className?: string;
  friendlyUrl: string;
  link: string;
  children: any;
  title?: string;
}

export const SetLink = (props: ISetLink) => {
  const {className, link, children, title, friendlyUrl} = props;
  const appStore = useStore($appStore);
  return (
    <>
      {link && link.length !== 0 ? (
        <a href={link} className={className} title={title} target="_blank" rel="noreferrer" aria-label={title}>
          {children}
        </a>
      ) : (
        <Link to={`/${appStore.language}/${friendlyUrl}`} className={className} title={title} aria-label={title}>
          {children}
        </Link>
      )}
    </>
  );
};

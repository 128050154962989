import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../../store/app/store';
import {sanitizeUppercase} from '../../../utils/utils';
import {Languages} from './Languages';
import './MobileNav.scss';

interface IMobilenav {
  data: any;
}

export const MobileNav = (props: IMobilenav) => {
  const {data} = props;
  const appStore = useStore($appStore);

  return (
    <div className={`mobileNav ${appStore.navIsOpen ? 'isOpened' : ''}`}>
      <div className="mobileNav-inner">
        {data &&
          data
            .filter((v: any) => v.data.navParent.iv.length === 0 && v.data.navIsActive[appStore.language] === true)
            .map((navItem: any, index: number) => (
              <div key={index} className={`navItem`}>
                {navItem.data.navLink.iv[0] ? (
                  <Link
                    to={`${appStore.language}/${navItem.data.navLink.iv[0].data.friendlyUrl[appStore.language]}`}
                    className="navItem-link"
                  >
                    {sanitizeUppercase(navItem.data.navTitle[appStore.language])}
                    <div className={`navItem-container`}></div>
                  </Link>
                ) : (
                  <>
                    <div className={`navItem-container`}>
                      <div className="navItem-container-title">
                        {sanitizeUppercase(navItem.data.navTitle[appStore.language])}
                      </div>
                      <div className={`navItem-container-submenu`}>
                        {data
                          .filter(
                            (v: any) =>
                              v.data.navParent.iv.length === 1 &&
                              v.data.navParent.iv[0].id === navItem.id &&
                              v.data.navIsActive[appStore.language] === true,
                          )
                          .map((child: any, i: number) => (
                            <div key={i} className="navItem-container-submenu-item">
                              {child.data.navLink.iv[0] && (
                                <Link
                                  to={`${appStore.language}/${
                                    child.data.navLink.iv[0].data.friendlyUrl[appStore.language]
                                  }`}
                                >
                                  {sanitizeUppercase(child.data.navTitle[appStore.language])}
                                </Link>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
        <div className="navItem language">
          <Languages />
        </div>
      </div>
    </div>
  );
};

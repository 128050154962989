import {assetsPath} from '../../../utils/utils';
import {Image} from '../../../components/Image/Image';

interface IDownloadImage {
  item: any;
}

export const DownloadImage = (props: IDownloadImage) => {
  const {item} = props;
  return (
    <>
      {item && (
        <a
          href={`${assetsPath}/${item.download && item.download.length > 0 && item.download[0].slug}?download=1`}
          className="downloads-image-section-list-item"
          target="_blank"
          rel={'noreferrer'}
        >
          <Image
            imageUrl={item.download && item.download.length > 0 && item.download[0].slug}
            config={'height=300&width=300&mode=min&cache=2630000&download=1'}
            imageAlt=""
            download
          />
          <div className="download-card">
            <div className="download-inner">
              <div className="download-button"></div>
              <div className="download-text">Download file</div>
            </div>
          </div>
        </a>
      )}
    </>
  );
};

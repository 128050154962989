import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../../store/app/store';
import {assetsPath, getUrl} from '../../../utils/utils';
import {SetLink} from '../../SetLink/SetLink';

import './FooterLegal.scss';

interface IFooterLegal {
  data: any;
  legalText: string;
}
export const FooterLegal = (props: IFooterLegal) => {
  const {data, legalText} = props;
  const appStore = useStore($appStore);
  return (
    <div className="copyright" style={{backgroundImage: `url(${assetsPath}/grass.svg`}}>
      <div className="copyright-text">
        &copy; {new Date().getFullYear()} {legalText}
      </div>
      <div className="copyright-links">
        {data &&
          data
            .filter((v: any) => v.data?.navIsActive[appStore.language] === true)
            .map((navItem: any, index: number) => (
              <Link
                to={getUrl(
                  appStore.language,
                  navItem.data.navLink.iv[0].__typename.toLowerCase(),
                  navItem.data.navLink.iv[0].data.friendlyUrl[appStore.language],
                )}
                key={index}
                title={navItem.data.navTitle[appStore.language]}
              >
                {navItem.data.navTitle[appStore.language]}
              </Link>
            ))}
      </div>
    </div>
  );
};

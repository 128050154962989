import React from 'react';
import PropTypes from 'prop-types';

interface IYoutubeVideo {
  videoId: string;
  videoTitle: string;
}

export const YoutubeVideo = (props: IYoutubeVideo) => {
  const {videoId, videoTitle} = props;

  return (
    <div className="video-responsive">
      <iframe
        width="100%"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={videoTitle}
      />
    </div>
  );
};

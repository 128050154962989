import {ProductList} from './ProductList';
import {Biologikes} from './subCategories/Biologikes';
import {NearaFylla} from './subCategories/NearaFylla';
import {Myrwdika} from './subCategories/Myrwdika';
import {SuperBowls} from './subCategories/SuperBowls';
import {Froutosalates} from './subCategories/Froutosalates';
import {Snack} from './subCategories/Snack';

import './SubCategories.scss';

interface ISubCategories {
  subCategories: any;
}

export const SubCategories = (props: ISubCategories) => {
  const {subCategories} = props;
  return (
    <div className="subCategories">
      {subCategories.map((item: any, index: number) => (
        <div className="subCategories-item" key={index}>
          {index === 0 ? (
            <div className={`categorySection ${item.flatData.type}`}>
              <div className={`categorySection-texts ${item.flatData.friendlyUrl}`}>
                {item.flatData.products && <ProductList products={item.flatData.products} />}
              </div>
            </div>
          ) : (
            <div className={`categorySection ${item.flatData.type}`}>
              <Biologikes item={item} />
              <NearaFylla item={item} />
              <Myrwdika item={item} />
              <SuperBowls item={item} />
              <Froutosalates item={item} />
              <Snack item={item} />
              <div className="categorySection-products">
                <ProductList products={item.flatData.products} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

import {useStore} from 'effector-react';
import {Helmet} from 'react-helmet-async';
import $appStore from '../../store/app/store';
// import {useLocation} from 'react-router-dom';
// import {useEffect} from 'react';
// import ReactGA4 from 'react-ga4';

export const Seo = () => {
  const appStore = useStore($appStore);
  // const location = useLocation();

  // useEffect(() => {
  //   if (appStore.gtm) {
  //     //}} && !ReactGA4.isInitialized) {
  //     ReactGA4.initialize(appStore.gtm);
  //   }
  //   // if (ReactGA4.isInitialized) {
  //   //   ReactGA4.send({hitType: 'pageview', page: location.pathname});
  //   // }
  //   // }, [appStore.gtm, location.pathname]);
  // }, [appStore.gtm]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{appStore.siteTitle}</title>
      {appStore.siteDescription && <meta name="description" content={appStore.siteDescription} />}
      {appStore.siteLdJson && (
        <script type="application/ld+json">{`
        ${appStore.siteLdJson}
    `}</script>
      )}
    </Helmet>
  );
};

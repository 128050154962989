import {Route, Routes, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {CONFIG, getContent} from '../service/httpService';
import config from '../config/config.json';
import {allRoutes} from '../service/graphQL';
import $appStore from '../store/app/store';
import {Category, CategoryIndex, Home, News, Pages, Products, Press, Downloads, DownloadCategories} from '../pages';
import Layout from '../components/Layout/Layout';
import {setLanguages, setPageRoutes} from '../store/app/events';
import {NewsDetails} from '../pages/NewsDetails/NewsDetails';
import {Tips} from '../pages/Tips/Tips';
import {Redirect} from '../pages/Redirect/Redirect';

interface IAllRoutes {
  routes: any;
}

export const AllRoutes = (props: IAllRoutes) => {
  const {routes} = props;
  // const [routes, setRoutes] = useState<any>();
  const appStore = useStore($appStore);

  // useEffect(() => {
  //   const getlanguages = async () => {
  //     const langArray: any[] = [];
  //     const data: any = await getContent('GET', `api/apps/${CONFIG.appName}/languages`, '', '');
  //     // console.log(data.items.iso2Code);
  //     data.items.map((lang: any) => langArray.push({iso: lang.iso2Code, name: lang.englishName}));
  //     setLanguages(langArray);
  //   };

  //   getlanguages();
  // }, []);

  // useEffect(() => {
  //   const query = `{
  //   ${allRoutes}
  //   }`;
  //   const getUrl = async () => {
  //     const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, '');
  //     setRoutes(data.data);
  //   };

  //   getUrl();
  // }, []);

  // useEffect(() => {
  //   const getPages = () => {
  //     let urls: any[] = [];
  //     config.paths.map((item) => {
  //       const path: any = item.path;
  //       routes[item.query].map((page: any) => {
  //         appStore.languages.map((lang: any) => {
  //           if (page.data.friendlyUrl[lang.iso]) {
  //             const exists = urls.filter((v: any) => v.lang === lang.iso && v.url === page.data.friendlyUrl[lang.iso]);
  //             if (exists.length > 0) {
  //               const data: any = getContent(
  //                 'GET',
  //                 `api/content/${CONFIG.appName}/${item.name}/${page.id}`,
  //                 '',
  //                 '',
  //               ).then((res: any) => {
  //                 // console.log(res.data.friendlyUrl[lang.iso]);
  //                 res.data.friendlyUrl[lang.iso] = `${page.data.friendlyUrl[lang.iso]}-${exists.length}`;
  //                 const postData: any = getContent(
  //                   'PUT',
  //                   `api/content/${CONFIG.appName}/${item.name}/${page.id}`,
  //                   res.data,
  //                   lang.iso,
  //                 );
  //                 urls.push({
  //                   type: item.name,
  //                   id: page.id,
  //                   path: path[lang.iso],
  //                   lang: lang.iso,
  //                   url: `${page.data.friendlyUrl[lang.iso]}-${exists.length}`,
  //                 });
  //               });
  //             } else {
  //               urls.push({
  //                 type: item.name,
  //                 id: page.id,
  //                 path: path[lang.iso],
  //                 lang: lang.iso,
  //                 url: page.data.friendlyUrl[lang.iso],
  //               });
  //             }
  //           }
  //         });
  //       });
  //     });

  //     return urls;
  //   };
  //   if (appStore.languages.length > 0 && routes) {
  //     setPageRoutes(getPages());
  //   }
  // }, [appStore.languages, routes]);

  return (
    <>
      {/* {appStore && <pre>{JSON.stringify(appStore, null, 2)}</pre>} */}
      {/* {appStore.pageRoutes && <pre>{JSON.stringify(appStore.pageRoutes, null, 2)}</pre>} */}
      {routes && appStore.pageRoutes && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/en" element={<Home />} />
            {appStore.languages.map((item: any, index: number) => (
              <Route key={index} path={`/${item.iso}`} element={<Home />} />
            ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'pages')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<Pages slug={`${item.url}`} />}
                />
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'categories')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<Category slug={`${item.url}`} />}
                />
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'categoryIndex')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<CategoryIndex slug={`${item.url}`} />}
                />
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'products')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<Products slug={`${item.url}`} id={item.id} />}
                />
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'articles')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<NewsDetails slug={`${item.url}`} id={item.id} />}
                />
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'news')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<News slug={`${item.url}`} />}
                ></Route>
              ))}
            {/* <Route path={`/el/ta-nea-mas/:slug`} element={<NewsDetails />} />
            <Route path={`/en/news/:slug`} element={<NewsDetails />} /> */}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'press')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<Press slug={`${item.url}`} />}
                />
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'downloads')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<Downloads slug={`${item.url}`} />}
                ></Route>
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'downloaditems')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<DownloadCategories slug={`${item.url}`} id={item.id} />}
                ></Route>
              ))}
            {appStore.pageRoutes
              .filter((v: any) => v.type === 'tipspage')
              .map((item: any, index: number) => (
                <Route
                  key={index}
                  path={`/${item.lang}${item.path}${item.url}`}
                  element={<Tips slug={`${item.url}`} id={item.id} />}
                ></Route>
              ))}
            <Route path="/el/:slug" element={<Redirect />} />
            <Route path="/el/:category/:slug" element={<Redirect />} />
            {/* <Route path={`/el/downloads/:slug`} element={<DownloadCategories />} /> */}

            {/* {pageRoutes.map((item: any, index: number) => (
              <>
                <Route
                  path={`/el/${item.data.friendlyUrl.el}`}
                  element={<Category slug={`${item.data.friendlyUrl.el}`} lang="el" />}
                />
                <Route
                  path={`/en/${item.data.friendlyUrl.en}`}
                  element={<Category slug={`${item.data.friendlyUrl.en}`} lang="en" />}
                />
              </>
            ))} */}
          </Route>
        </Routes>
      )}
    </>
  );
};

import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {useLocation, useParams} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {setDescription, setIsLoading, setLdJson, setModalImage, setTitle} from '../../store/app/events';
import {setCurrentPageId} from '../../store/app/events';
import {MediaNav} from '../../components/MediaNav/MediaNav';
import {Image} from '../../components/Image/Image';
import {pressGraphQl} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';

import {sanitizeUppercase} from '../../utils/utils';
import {YoutubeVideo} from './components/YoutubeVideo';

import './Press.scss';

interface IPress {
  slug: string;
  id?: string;
  lang?: string;
}

export const Press = (props: IPress) => {
  const {slug, id, lang} = props;
  const appStore = useStore($appStore);
  // const {slug} = useParams();
  const location = useLocation();
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${pressGraphQl}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryMediaContents);
      setTitle(data.data.queryMediaContents[0].flatData.pageTItle);
      setDescription(data.data.queryMediaContents[0].flatData.pageDescription);
      setLdJson(data.data.queryMediaContents[0].flatData.pageLdJson);
    };
    if (slug) getPage(slug);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) setCurrentPageId(slug);
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);

  return (
    <div className="press">
      <MediaNav selected={slug} />
      {pageData && pageData[0] && (
        <div className="press-images">
          <div className="press-images-title">{sanitizeUppercase(pageData[0].flatData.title)}</div>
          <div
            className="press-images-subtitle"
            dangerouslySetInnerHTML={{__html: pageData[0].flatData.sectionDescription}}
          />
          <div className="press-images-list">
            {pageData[0].flatData.mediaFiles
              .filter((v: any) => v.isActive === true)
              .map((item: any, index: number) => (
                <div
                  className="press-images-list-item"
                  key={index}
                  onClick={() => {
                    setModalImage(item.image[0].slug);
                    // setIsLoading(true);
                  }}
                >
                  <Image
                    imageUrl={item.image[0].slug}
                    config="width=435&height=611&cache=2630000&method=CropUpsize"
                    imageAlt={item.title}
                  />
                </div>
              ))}
          </div>
        </div>
      )}

      {pageData && pageData[0] && (
        <div className="press-videos">
          <div className="press-videos-title">{sanitizeUppercase(pageData[0].flatData.videoTitle)}</div>
          <div className="press-videos-list">
            {pageData[0].flatData.videoFiles
              .filter((v: any) => v.isActive === true)
              .map((item: any, index: number) => (
                <div className="press-videos-list-item" key={index}>
                  <YoutubeVideo videoId={item.youtubeVideoId} videoTitle={item.videoTitle} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

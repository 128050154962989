import {Image} from '../../Image';
import {SetLink} from '../../SetLink/SetLink';

import './WeLoveSaladsTeaser.scss';

interface ISectionWeLoveSalads {
  title: string;
  text: string;
  link: string;
  url: any;
  style: string;
}
export const WeLoveSaladsTeaser = (props: ISectionWeLoveSalads) => {
  const {title, text, link, style, url} = props;
  return (
    <div className="we-love-salads-teaser style-2">
      <SetLink link={link} title={title} friendlyUrl={url?.data.friendlyUrl}>
        <div className="wls-container">
          <div className="wls-titlebox-container">
            <div className="left-line"></div>
            <div className="wls-titlebox">
              <div className="wls-titlebox-text">
                <Image imageUrl="welovesalads-logo.svg" config="" imageAlt={title} />
              </div>
            </div>
            <div className="right-line"></div>
          </div>
          <div className="wls-text" dangerouslySetInnerHTML={{__html: text}} />
        </div>
      </SetLink>
    </div>
  );
};

import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import {sanitizeUppercase} from '../../../utils/utils';
import {Languages} from './Languages';
import {$appStore} from '../../../store/app/store';
import './Navigation.scss';

interface INavigation {
  data: any;
  settings: any;
}
export const Navigation = (props: INavigation) => {
  const appStore = useStore($appStore);
  const {data, settings} = props;
  const [activeLanguages, setActiveLanguages] = useState<any[]>([]);
  useEffect(() => {
    const activeLanguagesList: any[] = [];
    const languages = [
      {
        language: 'english',
        active: settings.english,
      },
      {
        language: 'greek',
        active: settings.greek,
      },
    ];

    appStore.languages.map((e: any) => {
      const activeLanguage = languages.filter(
        (lang: any) => lang.language === e.name.toLowerCase() && lang.active.iv === true,
      );

      if (activeLanguage.length > 0) {
        activeLanguagesList.push(e.name);
      }
    });
    setActiveLanguages(activeLanguagesList);
    // console.log(activeLanguagesList);
  }, [appStore.languages, settings]);

  return (
    <nav>
      {data &&
        data
          .filter((v: any) => v.data.navParent.iv.length === 0 && v.data.navIsActive[appStore.language] === true)
          .map((navItem: any, index: number) => (
            <div key={index} className={`navItem`}>
              {navItem.data.navLink.iv[0] ? (
                <Link
                  to={`${appStore.language}/${navItem.data.navLink.iv[0].data.friendlyUrl[appStore.language]}`}
                  className="navItem-link"
                >
                  {sanitizeUppercase(navItem.data.navTitle[appStore.language])}
                  <div className={`navItem-container`}></div>
                </Link>
              ) : (
                <>
                  <div className={`navItem-container`}>
                    {sanitizeUppercase(navItem.data.navTitle[appStore.language])}
                    <div className={`navItem-container-submenu`}>
                      {data
                        .filter(
                          (v: any) =>
                            v.data.navParent.iv.length === 1 &&
                            v.data.navParent.iv[0].id === navItem.id &&
                            v.data.navIsActive[appStore.language] === true,
                        )
                        .map((child: any, i: number) => (
                          <div key={i} className="navItem-container-submenu-item">
                            {child.data.navLink.iv[0] && (
                              <Link
                                to={`${appStore.language}/${
                                  child.data.navLink.iv[0].data.friendlyUrl[appStore.language]
                                }`}
                              >
                                {sanitizeUppercase(child.data.navTitle[appStore.language])}
                              </Link>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
      {/* {appStore.languages.filter()} */}
      {activeLanguages && activeLanguages.length > 1 && (
        <div className="navItem language">
          <Languages />
        </div>
      )}
    </nav>
  );
};

import {useEffect, useState} from 'react';
import {Image} from '../../../components/Image';
import {SetLink} from '../../../components/SetLink/SetLink';
import {assetsPath, shuffleArray} from '../../../utils/utils';

import './SectionTips.scss';
interface ISectionTips {
  title: string;
  link: string;
  image: string;
  tips: any;
  url: any;
}

export const SectionTips = (props: ISectionTips) => {
  const {title, link, image, tips, url} = props;
  const [tipIndex, setTipIndex] = useState<number | null>();
  const [tip, setTip] = useState<any>();

  useEffect(() => {
    if (tips) {
      setTip(shuffleArray(tips, false));
    }
  }, [tips]);

  return (
    <div className="tips">
      <div className="ps-divider ps-divider-top" />
      {tip && (
        <div className="tip">
          <div className="freskoulis">
            <img className="freskoulis" src={`${assetsPath}/${image}?width=350&cache=2630000`} alt={title} />
          </div>
          <div className="tip-content">
            <div className="tip-main">
              <div className="tip-image">
                <img className="star" src={`${assetsPath}/tips-star.png?cache=2630000`} alt={title} />
              </div>
              <div className="text" dangerouslySetInnerHTML={{__html: tip.flatData.text}} />
            </div>
            <div className="nav">
              <SetLink link={link} title={title} friendlyUrl={url ? url.flatData.friendlyUrl : link}>
                {title}
              </SetLink>
            </div>
          </div>
        </div>
      )}
      <div className="ps-divider" />
    </div>
  );
};

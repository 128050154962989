import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
import {setCurrentPageId} from '../../store/app/events';
import {MediaNav} from '../../components/MediaNav/MediaNav';
import {downloadsGraphQl} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';
import {getUrl, sanitizeUppercase} from '../../utils/utils';
import {DownloadImage} from './components/DownloadImage';
import {DownloadCatalogue} from './components/DownloadCatalogue';
import {Image} from '../../components/Image/Image';

import './Downloads.scss';

interface IDownloads {
  slug: string;
  id?: string;
  lang?: string;
}

export const Downloads = (props: IDownloads) => {
  const {slug} = props;
  const appStore = useStore($appStore);

  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${downloadsGraphQl}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryDownloadsContents);
      setTitle(data.data.queryDownloadsContents[0].flatData.pageTitle);
      setDescription(data.data.queryDownloadsContents[0].flatData.pageDescription);
      setLdJson(data.data.queryDownloadsContents[0].flatData.pageLdJson);
    };
    if (slug) getPage(slug);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) setCurrentPageId(slug);
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);

  return (
    <div className="downloads">
      <MediaNav selected={slug} />
      {pageData && pageData[0] && (
        <div className="downloads-image">
          <div className="downloads-image-title">{sanitizeUppercase(pageData[0].flatData.pageTItle)}</div>
          <div
            className="downloads-image-subtitle"
            dangerouslySetInnerHTML={{__html: pageData[0].flatData.pageSubtitle}}
          />
          {pageData[0].flatData.sections
            .filter((v: any) => v.flatData.downloadType === 'Image')
            .map((section: any, index: number) => (
              <div className="downloads-image-section" key={index}>
                <div className="downloads-image-section-title">{section.flatData.title}</div>
                <Link
                  to={getUrl(appStore.language, 'downloaditems', section.flatData.friendlyUrl)}
                  className="downloads-image-section-subtitle link"
                >
                  {section.flatData.subtitle}
                  <div className="arrow">
                    <Image
                      imageUrl="arrow.svg"
                      imageAlt={section.flatData.subtitle}
                      config={'width=55&cache=2630000'}
                      imageWidth={'55px'}
                    />
                  </div>
                </Link>
                <div className="downloads-image-section-list">
                  {section.flatData.items
                    .filter((v: any, index: number) => index < 3)
                    .map((item: any, index: number) => (
                      <DownloadImage item={item} key={index} />
                    ))}
                </div>
              </div>
            ))}
        </div>
      )}

      <div className="downloads-catalogue">
        {pageData &&
          pageData[0] &&
          pageData[0].flatData.sections
            .filter((v: any) => v.flatData.downloadType === 'Catalogue')
            .map((section: any, index: number) => (
              <div className="downloads-catalogue-section" key={index}>
                <div className="downloads-catalogue-section-title">{section.flatData.title}</div>
                <Link
                  to={getUrl(appStore.language, 'downloaditems', section.flatData.friendlyUrl)}
                  className="downloads-catalogue-section-subtitle link"
                >
                  {section.flatData.subtitle}
                  <div className="arrow">
                    <Image
                      imageUrl="arrow.svg"
                      imageAlt={section.flatData.subtitle}
                      config={'width=55&cache=2630000'}
                      imageWidth={'55px'}
                    />
                  </div>
                </Link>
                <div className="downloads-catalogue-section-list">
                  {section.flatData.items
                    .filter((item: any, index: number) => index < 4)
                    .map((item: any, index: number) => (
                      <DownloadCatalogue item={item} key={index} />
                    ))}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../../store/app/store';
import {getUrl, sanitizeUppercase} from '../../../utils/utils';
import {SetLink} from '../../SetLink/SetLink';

import './FooterNav.scss';
interface IFooterNav {
  data: any;
}
export const FooterNav = (props: IFooterNav) => {
  const {data} = props;
  const appStore = useStore($appStore);
  return (
    <div className="footer-nav">
      {data &&
        data
          .filter((v: any) => v.data.navIsActive[appStore.language] === true)
          .map((navItem: any, index: number) => (
            <Link
              to={getUrl(
                appStore.language,
                navItem.data.navLink.iv[0].__typename.toLowerCase(),
                navItem.data.navLink.iv[0].data.friendlyUrl[appStore.language],
              )}
              key={index}
              title={navItem.data.navTitle[appStore.language]}
            >
              {sanitizeUppercase(navItem.data.navTitle[appStore.language])}
            </Link>
          ))}
    </div>
  );
};

const friendlyUrlLangAll = `
__typename
data {
  friendlyUrl {
    el
    en
  }
}
`;
const friendlyUrlLangSingleLanguage = `
__typename
flatData {
  friendlyUrl
}
`;
const friendlyUrlShemaAll = `
...on Products {
  ${friendlyUrlLangAll}
}
...on Categories {
  ${friendlyUrlLangAll}
}
...on Pages {
  ${friendlyUrlLangAll}
}
...on Downloads {
  ${friendlyUrlLangAll}
}
...on Media  {
  ${friendlyUrlLangAll}
}
...on CategoryIndexPage {
  ${friendlyUrlLangAll}
}
...on Tipspage {
  ${friendlyUrlLangAll}
}
...on News {
  ${friendlyUrlLangAll}
}`;
const friendlyUrlShemaSingleLanguage = `
...on Products {
  ${friendlyUrlLangSingleLanguage}
}
...on Categories {
  ${friendlyUrlLangSingleLanguage}
}
...on Pages {
  ${friendlyUrlLangSingleLanguage}
}
...on Downloads {
  ${friendlyUrlLangSingleLanguage}
}
...on Media  {
  ${friendlyUrlLangSingleLanguage}
}
...on CategoryIndexPage {
  ${friendlyUrlLangSingleLanguage}
}
...on Tipspage {
  ${friendlyUrlLangSingleLanguage}
}
...on News {
  ${friendlyUrlLangSingleLanguage}
}`;

export const settingsGraphQL = `
     querySettingsContents {
    data {
      english {iv}
      greek {iv}
      footerLegalText {
        el
        en
      }
      logo {
        iv {
          slug
        }
      }
      siteName {
        el
        en
      }
      productNutritionalText {
        el
        en
      }
      weLoveSaladsIsActive {
        el
        en
      }
      weLoveSaladsLink {
        iv
      }
      weLoveSaladsUrl {
        iv {
        ${friendlyUrlShemaAll}
        }
      }
      weLoveSaladsText {
        el
        en
      }
      weLoveSaladsStyle {
        iv
      }
      weLoveSaladsTitle {
        el
        en
      }
      instagramIsActive {
        el
        en
      }
      instagramToken {
        iv
      }
      googleRecaptcha {
        iv
      }
      googleTagManager {
        iv
      }
      prosferoumeIsActive {
        el
        en
      }
      prosferoumeTitle {
        el
        en
      }
      prosferoumeSubtitle {
        el
        en
      }
      prosferoumeLink {
        el
        en
      }
      prosferoumeUrl {
        iv {
         ${friendlyUrlShemaAll}
        }
      }
      prosferoumeImage {
        iv {
          slug
        }
      }
      socialTitle {
        el
        en
      }
      socialSubtitle {
        el
        en
      }
      socialItems {
        iv {
          itemTitle
          itemUrl
          itemImage {
            slug
          }
        }
      }
      pageTitle {
          el
          en
      }
      pageDescription
      {
        el
        en
      }
      pageLdJson {
        el
        en
      }
    }
  }
`;

export const navigationGraphQL = `
queryNavigationContents {
    flatData {
      headerNavItems {
        id
        data {
          navIsActive {
            el
            en
          }
      		navTitle {
            el
            en
          }
          navLink {
            iv {
              ${friendlyUrlShemaAll}
            }
          }
  				navParent {
            iv {
              id

            }
          }
        }
      }

      footerNavItems {
        data {
					navIsActive{
            el
            en
          }
          navTitle{
            el
            en
          }
          navLink {
            iv {
              ${friendlyUrlShemaAll}
            }
          }
        }
      }

      legalNavItems {
        data {
					navIsActive{
            el
            en
          }
          navTitle{
            el
            en
          }
          navLink {
            iv {
              ${friendlyUrlShemaAll}
            }
          }
        }
      }

      mediaNavItems {
        data {
					navIsActive{
            el
            en
          }
          navTitle{
            el
            en
          }
          navLink {
            iv {
              ${friendlyUrlShemaAll}
            }
          }
        }
      }


    }
  }`;

export const homeGraphQL = `
queryHomePageContents {
      id
      flatData {
        slider
        weLoveSaladsLink
        weLoveSaladsUrl {
         ${friendlyUrlShemaSingleLanguage}
        }

        weLoveSaladsTitle
        weLoveSaladsLinkText
        weLoveSaladsProducts {
          flatData {
            friendlyUrl
            image {
              slug
            }
            icon {
              flatData {
                icon {
                  slug
                }
              }
            }
            title
            title2
          }
        }
        paragwgoiTitle
        paragwgoiText
        paragwgoiLink
        paragwgoiUrl {
           ${friendlyUrlShemaSingleLanguage}
        }
        paragwgoiBackgroundImage {
          slug
        }
        tipsTitle
        tipsLink
        tipsUrl {
          ${friendlyUrlShemaSingleLanguage}
        }
        tipsImage {
          slug
        }
        nearaTitle
        nearaSubtitle
        nearaLink
        nearaFyllaUrl {
          ${friendlyUrlShemaSingleLanguage}
        }
        nearaLinkText
        nearaImage {
          slug
        }
        pageTItle
        pageDescription
        ldJson
      }
    }
`;

export const pagesGraphQL = `
  queryPagesContents(filter: "data/friendlyUrl/#CURRENT_LANGUAGE# eq '#SLUG#'") {
    id
    flatData {
      isContact
      friendlyUrl
      subtitle
      title
      pageTitle
      pageDescription
      pageFooter
      pageHeaderImage {
        slug
      }
      sections {
        title
        subtitle
        text
        image {
          slug
        }
        divider
      }
      pageTitle
      pageDescription
      pageLdJson
    }
  }
  `;

export const slider1GraphQL = `
  querySlider1Contents {
    flatData {
      mainTitle
      mainLink
      mainText
      mainColor
      url {
         ${friendlyUrlShemaSingleLanguage}
      }
      slides {
        isActive
        title
        link
        url {
          ${friendlyUrlShemaSingleLanguage}
        }
        text
        color
        image {
          slug
        }
      }
    }
  }
  `;

export const tipsGraphQL = `queryTipsContents(filter: "data/isActive/#CURRENT_LANGUAGE# eq true") {
      flatData {
        title
        text
      }
    }
    `;

export const allRoutes = `
  queryCategoryIndexPageContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryTipspageContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryCategoriesContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryProductsContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryNewsContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryArticlesContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryMediaContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryDownloadsContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryDownloaditemsContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }
  queryPagesContents {
    id
    data {
      friendlyUrl {
        el
        en
      }
    }
  }


`;

export const parentCategoryGraphQL = `
queryCategoriesContents(filter: "data/friendlyUrl/#LANG# eq '#SLUG#' ") {
		id
    flatData {
      parentCattegory {
        id
      }
      friendlyUrl
      categoryName
      title
      subtitle
      text
      icon {
        flatData {
          icon{
            slug
          }
        }
      }
      backgroundImage {
        slug
      }
      textBackground
      type
      products {
        flatData {
          friendlyUrl
          icon {
            flatData {
              icon {
                slug
              }
            }
          }
          image {
            slug
          }
          title
          title2
          subtitle
        }
      }
      pageTitle
      pageDescription
      pageLdJson
    }
  }`;

export const articlesGraphQl = `
  queryNewsContents{
    flatData {
      title
      friendlyUrl
      pageTitle
      pageDescription
      pageLdJson
      subtitle
      articles {
        flatData {
        isActive
          articleStyle
          articleTitle
          artcleSubtitle
          articleShortDescription
          friendlyUrl
          articleImage {
            slug
          }
        }
      }
    }
  }
`;

export const articlesDetailsGraphQl = `
  queryArticlesContents(filter: "id eq '#ID#'") {
    flatData {
      articleTitle
      artcleSubtitle
      friendlyUrl
      pageTitle
      pageDescription
      pageLdJson
      articleImage {
        slug
      }
      sections {
        text
        image {
          slug
        }
      }
    }
  }
`;
export const productGraphQl = `
  queryProductsContents(filter: "id eq '#ID#' and data/isActive/#LANG# eq true") {
    id
    flatData {
      title
      title2
      categoryTitle
      friendlyUrl

      image {
        slug
      }
      iconDetailPage{
        slug
      }
      icon {
        flatData {
          title
          icon {
            slug
          }
        }
      }
    	icons {
        flatData {
          title
          icon {
            slug
          }
        }
      }
      ingredientsTitle
      ingredientsText
      ingredientsImage {
        slug
      }
      ingredients {
        flatData {
          title
          image {
            slug
          }
        }
      }
      temperature
      weightTitle
      weightText
      portions
      nutritionTable
      infoTitle
      infoText1
      infoText2
      infoText3
      recipesTitle
      recipesSubtitle
      recipesList {
        flatData {
          recipeTitle
          recipeLink
          recipeImage {
            slug
          }
        }
      }
      pageTitle
      pageDescription
      pageLdJson

    }
    referencingCategoriesContents {
    flatData {
      enableProductRecipes
      enableProductIngredients
    }
    }
  }
`;

export const downloadDetailsGraphQl = `
   queryDownloaditemsContents(filter: "id eq '#ID#'") {
    id
    flatData {
      downloadType
        friendlyUrl
        title
        subtitle
        pageTitle
        pageDescription
        pageLdJson
        items {
          type
          title
          subtitle
          download{
            slug
          }
        }
    }
  }
`;
export const pressGraphQl = `
queryMediaContents {
    flatData {
      title
      sectionDescription
      pageTItle
      pageDescription
      pageLdJson
			mediaFiles {
        isActive
        title
        image {
          slug
        }
      }
      videoTitle
      videoFiles {
        isActive
        videoTitle
        youtubeVideoId
      }
    }
  }
`;
export const downloadsGraphQl = `
  queryDownloadsContents {
    flatData {
      pageTItle
      pageSubtitle
      pageTitle
      pageDescription
      pageLdJson
     sections {
      flatData {
        downloadType
        friendlyUrl
        title
        subtitle
        items {
          type
          title
          subtitle
          download{
            slug
          }
        }
      }
    }
    }
  }
`;

export const tipsPageGraphQL = `
queryTipspageContents {
  id
  flatData {
    title
    subtitle
    image {
      slug
    }
    pageTitle
    pageDescription
    pageLdJson
  }
}
`;

export const categoryIndexGraphQL = `
queryCategoryIndexPageContents {
    id
    flatData {
      heroTitle
      heroSubtitle
      heroImage {
        slug
      }
      heroBackgroundImage {
        slug
      }
      heroLink {
        ${friendlyUrlShemaSingleLanguage}
      }
      pageFooter
      bioTitle
      bioSubtitle
      bioImage {
        slug
      }
      bioUrl {
        ${friendlyUrlShemaSingleLanguage}
      }
      ellinikhsGhsTitle
      ellinikhsGhsSubtitle
      ellinikhsGhsUrl {
        ${friendlyUrlShemaSingleLanguage}
      }
      ellinikhsGhsBackgroundImage {
        slug
      }
      ellinikhsGhsProducts {
        flatData {
          title
          friendlyUrl
          image {
            slug
          }
        }
      }
      freskaLaxanikaTitle
      freskaLaxanikaSubtitle
      freskaLaxanikaText
      freskaLaxanikaUrl {
        ${friendlyUrlShemaSingleLanguage}
      }
      freskaLaxanikaBackgroundImage {
        slug
      }
      nearaTitle
      nearaSubtitle
      nearaUrl {
        ${friendlyUrlShemaSingleLanguage}
      }
      nearaImage {
        slug
      }
      snackTitle
      snackSubtitle
      snackUrl{
        ${friendlyUrlShemaSingleLanguage}
      }
      snackText
      snackBackgroundColor
      snackImages {
        slug
      }
      pageTItle
      pageDescription
      pageLdJson
  }
  }
`;

export const subCategoryGraphQL = `
queryCategoriesContents(filter: "data/parentCattegory/iv eq '#PARENT_ID#' and data/isActive/el eq true", orderby:"data/categoryOrder/iv asc") {
  id
  flatData {
    categoryOrder
      categoryName
      title
      subtitle
      text
      friendlyUrl
      textBackground
      type
      backgroundImage {
        slug
      }
      icon {
        flatData {
          icon {
            slug
          }
          title
        }
      }
      products {
        flatData {
          title
          title2
          friendlyUrl
          secondaryIcon {
            ...on Icons {
              flatData {
                icon {
                  slug
                }
              }
            }
          }
          image {
            slug
          }

        }
      }
    }
  }
`;

export const redirectsGraphQL = `
  queryRedirectsContents(filter: "data/oldUrl/iv eq '#PATH#'") {
    flatData {
      oldUrl
      newUrl
    }
  }
`;

import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import {Image} from '../../../components/Image/Image';
import $appStore from '../../../store/app/store';
import {getUrl, sanitizeUppercase} from '../../../utils/utils';
import './ProductList.scss';

interface IProductList {
  products: any;
}
export const ProductList = (props: IProductList) => {
  const {products} = props;
  const appStore = useStore($appStore);

  return (
    <div className="subCategories-item-products">
      {products.map((product: any, index: number) => (
        <Link
          to={getUrl(appStore.language, 'products', product.flatData.friendlyUrl)}
          className="subCategories-item-products-item"
          key={index}
        >
          {product.flatData.image && (
            <div className="subCategories-item-products-item-image">
              <Image
                imageClass="image-zoom"
                config="width=300&height=300&mode=max&cache=2630000"
                imageUrl={product.flatData.image[0].slug}
              />
              {product.flatData.secondaryIcon && product.flatData.secondaryIcon[0] && (
                <div className="subCategories-item-products-item-image-icon">
                  <Image
                    imageClass=""
                    config="cache=2630000"
                    imageUrl={product.flatData.secondaryIcon[0].flatData.icon[0].slug}
                  />
                </div>
              )}
            </div>
          )}
          <div className="subCategories-item-products-item-title">{sanitizeUppercase(product.flatData.title)}</div>
          <div className="subCategories-item-products-item-title2">{product.flatData.title2}</div>
        </Link>
      ))}
    </div>
  );
};

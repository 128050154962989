import {Navigation} from './Navigation';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {assetsPath} from '../../../utils/utils';
import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';

import {setNavIsOpen} from '../../../store/app/events';
import {BurgerMenu} from './BurgerMenu';
import {MobileNav} from './MobileNav';

import './Header.scss';

interface IHeader {
  settings: any;
  headerNavData: any;
}
export const Header = (props: IHeader) => {
  const {settings, headerNavData} = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const appStore = useStore($appStore);
  const [direction, setDirection] = useState('up');
  let oldScrollY = 0;

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection('down');
    } else {
      setDirection('up');
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  useEffect(() => {
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 40) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
  }, []);

  return (
    <>
      {settings && headerNavData && (
        <header className={`${isCollapsed ? 'isCollapsed' : ''} ${direction}`}>
          <Link to={`${appStore.language === 'en' ? '/en' : '/'}`} className="logo">
            <img
              src={`${assetsPath}/${settings.logo.iv[0].slug}?cache=2630000`}
              alt={settings.siteName[appStore.language]}
            />
          </Link>
          {!appStore.isMobile ? (
            <Navigation data={headerNavData} settings={settings} />
          ) : (
            <div>
              <BurgerMenu />
              <MobileNav data={headerNavData} />
            </div>
          )}
        </header>
      )}
    </>
  );
};

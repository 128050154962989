import {Link} from 'react-router-dom';
import {Image} from '../../../components/Image';
import {SetLink} from '../../../components/SetLink/SetLink';
import {assetsPath} from '../../../utils/utils';

import './SectionParagwgoi.scss';
interface ISectionParagwgoi {
  title: string;
  text: string;
  link: string;
  image: string;
  url: any;
}
export const SectionParagwgoi = (props: ISectionParagwgoi) => {
  const {title, link, image, text, url} = props;
  return (
    <div className="sectionParagwgoi" style={{backgroundImage: `url(${assetsPath}/${image}?cache=2630000)`}}>
      <div className="overlay">
        <SetLink link={link} title={title} friendlyUrl={url.flatData.friendlyUrl}>
          <div className="wrap">
            <div className="title">{title}</div>
            <div className="subtitle" dangerouslySetInnerHTML={{__html: text}} />
            <div className="link">
              <Image
                imageUrl={`arrow-white.svg`}
                config="width=35&cache=2630000"
                imageWidth={'35px'}
                imageAlt={title}
              />
            </div>
          </div>
        </SetLink>
      </div>
    </div>
  );
};

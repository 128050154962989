import React from 'react';
import {Divider} from '../../components/Divider/Divider';
import {Image} from '../../components/Image/Image';

interface IPagesContent {
  pageData: any;
}

export const PagesContent = (props: IPagesContent) => {
  const {pageData} = props;
  return (
    <div className="contentInner">
      {!pageData.isContact && (
        <>
          <h3>{pageData.title}</h3>
          {pageData.subtitle && <h2>{pageData.subtitle}</h2>}
        </>
      )}
      {pageData.sections.map((item: any, index: number) => (
        <div className="contentSection" key={index}>
          {item.title && <h2 className="contentSection-title">{item.title}</h2>}
          {item.subtitle && <div className="contentSection-subtitle">{item.subtitle}</div>}
          {item.text && <div className="contentSection-text" dangerouslySetInnerHTML={{__html: item.text}} />}
          {item.image && item.image.length > 0 && (
            <div className="contentSection-images">
              {item.image.map((image: any, index: number) => (
                <Image
                  key={index}
                  imageUrl={image.slug}
                  imageAlt={item.title}
                  imageWidth="unset"
                  config="cache=2630000"
                />
              ))}
            </div>
          )}
          {item.divider && <Divider />}
        </div>
      ))}
    </div>
  );
};

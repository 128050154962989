import {assetsPath, getUrl, sanitizeUppercase} from '../../../utils/utils';
import {Image} from '../../../components/Image';
import {Link} from 'react-router-dom';
import {SetLink} from '../../../components/SetLink/SetLink';
import $appStore from '../../../store/app/store';
import {useStore} from 'effector-react';

import './SectionWeLoveSalads.scss';

interface ISectionWeLoveSalads {
  title: string;
  link: string;
  linkText: string;
  products: any;
  url: any;
}
export const SectionWeLoveSalads = (props: ISectionWeLoveSalads) => {
  const {title, link, linkText, products, url} = props;
  const appStore = useStore($appStore);

  return (
    <div className="weLoveSalads">
      <div
        className="weLoveSalads-header"
        style={{backgroundImage: `url(${assetsPath}/welovesalads-header-bg.png?cache=2630000)`}}
      >
        <h2>
          <span>WE</span>
          <Image imageUrl="heart.png" config="cache=2630000" imageAlt={title} />
          <span>SALADS</span>
        </h2>
      </div>
      <h4>{title}</h4>

      <div className="weLoveSalads-items">
        {products.map((product: any, index: number) => (
          <div className="item" key={index}>
            <Link to={getUrl(appStore.language, 'products', product.flatData.friendlyUrl)}>
              <div className="item-image">
                <Image
                  imageUrl={product.flatData.image[0].slug}
                  imageAlt={product.flatData.title}
                  imageClass="featured-products"
                  config={`width=350&quality=100`}
                />
              </div>
              <h3>{sanitizeUppercase(product.flatData.title)}</h3>
              <div className="text">{product.flatData.title2}</div>
            </Link>
            {/* <div className="weLoveSalads-product-icon">Icon: {product.flatData.icon[0].flatData.icon[0].slug}</div> */}
          </div>
        ))}
      </div>

      <SetLink className="weLoveSalads-link" link={link} friendlyUrl={url.flatData.friendlyUrl} title={linkText}>
        {linkText}
        <Image imageUrl={`arrow.svg`} imageWidth={'30px'} config="width=30&cache=2630000" imageAlt={linkText} />
      </SetLink>
    </div>
  );
};

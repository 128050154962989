import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {getUrl} from '../../../utils/utils';
import {Image} from '../../../components/Image';
import './Bio.scss';
import $appStore from '../../../store/app/store';
import {useStore} from 'effector-react';
interface IBio {
  title: string;
  subtitle: string;
  url: any;
  image: string;
}

export const Bio = (props: IBio) => {
  const {title, subtitle, image, url} = props;
  const appStore = useStore($appStore);

  useEffect(() => {
    url && getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl);
  }, [url]);

  return (
    <div className="categoriesBio">
      <Link
        to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
        className="categoriesBio-image"
      >
        <Image imageUrl={image} imageClass={''} imageAlt={title} config={'width=1000'} />
      </Link>
      <Link
        to={getUrl(appStore.language, url[0].__typename, url[0].flatData.friendlyUrl)}
        className="categoriesBio-texts"
      >
        <div className="categoriesBio-texts-title">{title}</div>
        <div className="categoriesBio-texts-subtitle">{subtitle}</div>
        <div className="categoriesBio-texts-arrow">
          <Image imageUrl="arrow.svg" imageAlt={title} config={'cache=2630000&width=30'} />
        </div>
      </Link>
    </div>
  );
};

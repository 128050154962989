import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {useLocation, useParams} from 'react-router-dom';
import {$appStore} from '../../store/app/store';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
import {categoryIndexGraphQL} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';

import {FooterBand} from '../../components/FooterBand/FooterBand';
import {Hero} from './components/Hero';

import './CategoryIndex.scss';
import {Bio} from './components/Bio';
import {FreskaFylla} from './components/FreskaFylla';
import {Snack} from './components/Snack';
import {FreskaLaxanika} from './components/FreskaLaxanika';
import {Ellhnikhs} from './components/Ellhnikhs';
interface ICategoryIndex {
  slug: string;
  id?: string;
  lang?: string;
}

export const CategoryIndex = (props: ICategoryIndex) => {
  const {slug, id, lang} = props;
  const appStore = useStore($appStore);
  const location = useLocation();
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${categoryIndexGraphQL}
}`;

      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      if (data) {
        setPageData(data.data.queryCategoryIndexPageContents[0].flatData);
        setTitle(data.data.queryCategoryIndexPageContents[0].flatData.pageTItle);
        setDescription(data.data.queryCategoryIndexPageContents[0].flatData.pageDescription);
        setLdJson(data.data.queryCategoryIndexPageContents[0].flatData.pageLdJson);
      }
    };
    if (slug) getPage(slug);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) setCurrentPageId(slug);
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);

  return (
    <div className="categoryIndex">
      {pageData && (
        <>
          <Hero
            title={pageData.heroTitle}
            subtitle={pageData.heroSubtitle}
            image={pageData.heroImage[0].slug}
            backgroundImage={pageData.heroBackgroundImage[0].slug}
            url={pageData.heroLink}
          />
          <Bio
            title={pageData.bioTitle}
            subtitle={pageData.bioSubtitle}
            url={pageData.bioUrl}
            image={pageData.bioImage[0].slug}
          />
          <FreskaFylla
            title={pageData.nearaTitle}
            subtitle={pageData.nearaSubtitle}
            url={pageData.nearaUrl}
            image={pageData.nearaImage[0].slug}
          />
          <Snack
            title={pageData.snackTitle}
            subtitle={pageData.snackSubtitle}
            url={pageData.snackUrl}
            images={pageData.snackImages}
            text={pageData.snackText}
            backgroundColor={pageData.snackBackgroundColor}
          />
          <FreskaLaxanika
            title={pageData.freskaLaxanikaTitle}
            subtitle={pageData.freskaLaxanikaSubtitle}
            text={pageData.freskaLaxanikaText}
            url={pageData.freskaLaxanikaUrl}
            image={pageData.freskaLaxanikaBackgroundImage[0].slug}
          />
          <Ellhnikhs
            title={pageData.ellinikhsGhsTitle}
            subtitle={pageData.ellinikhsGhsSubtitle}
            products={pageData.ellinikhsGhsProducts}
            url={pageData.ellinikhsGhsUrl}
            image={pageData.ellinikhsGhsBackgroundImage[0].slug}
          />
          <FooterBand text={pageData.pageFooter} className="categoryIndex-footer" />
        </>
      )}
    </div>
  );
};

import {assetsPath} from '../../../utils/utils';
import {Image} from '../../../components/Image/Image';

interface IDownloadCatalogue {
  item: any;
}

export const DownloadCatalogue = (props: IDownloadCatalogue) => {
  const {item} = props;
  return (
    <div className="downloads-catalogue-section-list-item">
      <div className="downloads-catalogue-section-list-texts">
        <div className="title2">{item.type}</div>
        <a href={`${assetsPath}/${item.download[0].slug}?download=1`} target="_blank" rel={'noreferrer'}>
          <div className="title">{item.title}</div>
        </a>
        <div className="text">
          <p>{item.subtitle}</p>
        </div>
      </div>
      <a href={`${assetsPath}/${item.download[0].slug}?download=1`} target="_blank" rel={'noreferrer'}>
        <div className="downloads-catalogue-section-list-item-image">
          <img src={`${assetsPath}/entypa-cover.png`} alt="Download" />
          <div className="download-card">
            <div className="download-inner">
              <div className="download-text">Download file</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

import {useStore} from 'effector-react';
import {Image} from '../../../components/Image';
import $appStore from '../../../store/app/store';
import {SetLink} from '../../SetLink/SetLink';

import './Prosferoume.scss';

interface IProsferoume {
  title: string;
  subtitle: string;
  link: string;
  image: string;
  url: any;
}
export const Prosferoume = (props: IProsferoume) => {
  const {title, subtitle, link, image, url} = props;
  const appStore = useStore($appStore);
  return (
    <div className="sharing">
      <SetLink link={link} title={title} friendlyUrl={url[0]?.data.friendlyUrl[appStore.language]}>
        <Image imageUrl={image} config="cache=14400&width=240" imageAlt={title} />
      </SetLink>
      <SetLink link={link} title={title} friendlyUrl={url[0]?.data.friendlyUrl[appStore.language]}>
        <h3> {title} </h3>
        <h4> {subtitle}</h4>
      </SetLink>
    </div>
  );
};

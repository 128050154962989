import axios, {AxiosResponse} from 'axios';
import {setIsLoading} from '../store/app/events';
export const CONFIG = {
  url: 'https://cms.greymatter.gr',
  appName: 'freskoulis',
  clientId: 'freskoulis:website',
  clientSecret: '1xxcp6rz1fyxxgddpxesj0hciskmfpbzu8si5h8x7i0x',
};

const getBearerToken = () => {
  return localStorage.getItem('token');
};

const setBearerToken = (token: string) => {
  localStorage.setItem('token', token);
};

const clearBearerToken = () => {
  localStorage.removeItem('token');
};

export const fetchBearerToken = async () => {
  // Check if we have already a bearer token in local store.
  let token = getBearerToken();

  if (token) {
    return token;
  }

  const data = `grant_type=client_credentials&scope=squidex-api&client_id=${CONFIG.clientId}&client_secret=${CONFIG.clientSecret}`;

  // Get the bearer token. Ensure that we use a client id with readonly permissions.
  const response = await axios(buildUrl('identity-server/connect/token'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  });

  if (response.statusText !== 'OK') {
    throw new Error(`Failed to retrieve token, got ${response.statusText}`);
  }

  const json = await response.data;

  token = json.access_token;

  token && setBearerToken(token);

  return token;
};

export const getContent = async (method: 'POST' | 'GET' | 'PUT', url: string, data: any, language: string) => {
  // Fetch the bearer token.
  setIsLoading(true);
  const token = await fetchBearerToken();
  const retry = true;
  let rdata: [] | null = null;

  if (token) {
    await axios(buildUrl(url), {
      method: method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Languages': language,
      },
      data: data ? JSON.stringify(data) : null,
    })
      .then((response) => {
        rdata = response.data;
        // setTimeout(() => {
        //   // setIsLoading(false);
        // }, 300);

        return response.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          clearBearerToken();
          // if (retry) {
          //   return getContent(method, url, data, language);
          // }
        }
      });
    return rdata;
  }
};

function buildUrl(url: any) {
  if (url.length > 0 && url.startsWith('/')) {
    url = url.substr(1);
  }

  const result = `${CONFIG.url}/${url}`;

  return result;
}

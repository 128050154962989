import React from 'react';
import {assetsPath, sanitizeUppercase} from '../../../../utils/utils';
import {Image} from '../../../../components/Image';

import './Myrwdika.scss';
interface ICategory {
  item: any;
}

export const Myrwdika = (props: ICategory) => {
  const {item} = props;
  return (
    <>
      {item.flatData.type === 'freska-myrwdika' && (
        <>
          <div className="categorySection-header" id={item.flatData.friendlyUrl}>
            <div className="categorySection-header-title">{sanitizeUppercase(item.flatData.categoryName)}</div>
          </div>
          <div
            className={`categorySection-texts ${
              item.flatData.type && item.flatData.type.replace(/\s/g, '-').toLowerCase()
            } `}
            style={{
              backgroundImage:
                item.flatData.backgroundImage !== null && item.flatData.backgroundImage[0]
                  ? `url(${assetsPath}/${item.flatData?.backgroundImage[0].slug}`
                  : 'none',
            }}
          >
            {item.flatData.icon && (
              <div className="categorySection-inner-icon">
                <Image imageClass="" config="cache=2630000" imageUrl={item.flatData.icon[0].flatData.icon[0].slug} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

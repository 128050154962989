import {useState} from 'react';
import {Link} from 'react-router-dom';
import config from '../../../config/config.json';
import {assetsPath, getUrl} from '../../../utils/utils';
import {Image} from '../../../components/Image';
import './SectionSlider.scss';
import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';

interface ISectionSlider {
  slider: string;
  slider1: any;
}

export const SectionSlider = (props: ISectionSlider) => {
  const {slider, slider1} = props;
  const appStore = useStore($appStore);
  const [selectedSlider, setSelectedSlider] = useState<number | null>(null);
  return (
    <div>
      {slider1 && slider1.flatData && (
        <div className="hero hero-1">
          <div className="col-1">
            {slider1.flatData.slides.map((slide: any, index: number) => (
              <div
                key={index}
                className={`box color-${slide.color.toLowerCase()} ${selectedSlider === index ? 'isSelected' : ''}`}
                onClick={() => {
                  selectedSlider !== index ? setSelectedSlider(index) : setSelectedSlider(null);
                }}
              >
                <Image
                  imageUrl={slide.image[0].slug}
                  config="width=320&height=320?cache=14400&quality=100"
                  imageAlt={slide.title}
                  position={'absolute'}
                />
                <div className="box-frame">
                  <div className="box-button">+</div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-2">
            <div
              className={`hero-content hero-main-content color-${slider1.flatData.mainColor.toLowerCase()} ${
                selectedSlider !== null ? ' isHidden' : ''
              }`}
            >
              <Link
                to={
                  slider1.flatData.url && slider1.flatData.url[0] && appStore.language
                    ? getUrl(
                        appStore.language,
                        slider1.flatData.url[0].__typename.toLowerCase(),
                        slider1.flatData.url[0].flatData.friendlyUrl,
                      )
                    : slider1.flatData.mainLink
                }
                aria-label={slider1.flatData.mainTitle}
              >
                <div className="hero-title">{slider1.flatData.mainTitle}</div>
                <div className="hero-text" dangerouslySetInnerHTML={{__html: slider1.flatData.mainText}} />
                <div className="hero-link">
                  <Image
                    imageUrl={`${
                      slider1.flatData.mainColor.toLowerCase() === 'default' ? 'arrow.svg' : 'arrow-white.svg'
                    }`}
                    imageWidth={'55px'}
                    config="cache=2630000&width=55"
                    imageAlt={slider1.flatData.mainTitle}
                  />
                </div>
              </Link>
            </div>
            {slider1.flatData.slides.map((slide: any, index: number) => (
              <div
                key={index}
                className={`hero-content color-${slide.color.toLowerCase()} ${
                  selectedSlider === index ? '' : ' isHidden'
                }`}
              >
                <a
                  href={
                    slide.link
                      ? `${slide.link}`
                      : getUrl(
                          appStore.language,
                          slide.url[0].__typename.toLowerCase(),
                          slide.url[0].flatData.friendlyUrl,
                        )
                  }
                  target={slide.link ? '_blank' : '_self'}
                  rel={'noreferrer'}
                  aria-label={slide.title}
                >
                  <div className="hero-title">{slide.title}</div>
                  <div className="hero-text" dangerouslySetInnerHTML={{__html: slide.text}} />
                  <div className="hero-link">
                    <Image
                      imageUrl={`${slide.color.toLowerCase() === 'default' ? 'arrow.svg' : 'arrow-white.svg'}`}
                      imageWidth={'55px'}
                      config="cache=2630000&width=55"
                      imageAlt={slider1.flatData.mainTitle}
                    />
                  </div>
                </a>

                <div className="box-close" onClick={() => setSelectedSlider(null)}>
                  <span></span>
                  <span></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

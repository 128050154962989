import {assetsPath, sanitizeUppercase} from '../../../utils/utils';
import {Image} from '../../../components/Image/Image';
import useWindowSize from '../../../hooks/useWindowSize';

import './Hero.scss';

interface IHero {
  backgroundImage: string;
  textBackground: string;
  title: string;
  subtitle: string;
  text: string;
  icon: string;
}
export const Hero = (props: IHero) => {
  const {backgroundImage, textBackground, title, subtitle, text, icon} = props;
  const {width} = useWindowSize();

  return (
    <div
      className="category-hero"
      // style={{backgroundImage: `url(${assetsPath}/${backgroundImage}?width=${width}&cache=2630000)`}}
    >
      <Image imageUrl={backgroundImage} config={`width=${width}&cache=2630000`} imageClass="backgroundImage" />
      <div className={`category-hero-inner hero-${textBackground.toLowerCase()}`}>
        <h1 className="category-hero-inner-title">{title.length < 50 ? sanitizeUppercase(title) : title}</h1>
        {subtitle && <div className="category-hero-inner-subtitle">{subtitle}</div>}
        <div className="category-hero-inner-text" dangerouslySetInnerHTML={{__html: text}} />
        {icon && (
          <div className="category-hero-inner-icon">
            <Image imageUrl={icon} imageAlt={title} />
          </div>
        )}
      </div>
    </div>
  );
};

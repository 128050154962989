import {useStore} from 'effector-react';
import {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {setLanguage} from '../../../store/app/events';
import $appStore from '../../../store/app/store';
import './Languages.scss';

export const Languages = () => {
  const appStore = useStore($appStore);
  const navigate = useNavigate();
  const {slug} = useParams();
  const location = useLocation();
  useEffect(() => {
    const pathArr = location.pathname.split('/');
    if (pathArr[1] === 'en') {
      setLanguage('en');
    } else {
      setLanguage('el');
    }
  }, [location.pathname]);
  const [currentLanguage, setCurrentLanguage] = useState('');

  // const getFriendlyUrls = async (lang: string) => {
  //   const data: any = await getContent('GET', `api/content/freskoulis?ids=${appStore.currentPageId}`, [], 'el,en');
  //   const friendlyUrl = data.items[0].data.friendlyUrl[lang];
  //   const path = data.items[0].schemaName;
  //   navigate(`${path}/${friendlyUrl}`);
  // };

  const changeLanguage = (language: string) => {
    setCurrentLanguage(language);

    // setLanguage(language);
    // localStorage.setItem('lang', language);
    // getFriendlyUrls(language);
    // const data: any = async getContent('GET', `api/content/freskoulis?ids=${appStore.currentPageId}`, [], 'el,en');
    // console.log('1111', data);
    // setLanguage(language);
  };
  useEffect(() => {
    const getUrl = () => {
      let path = '';
      const currentPage = appStore.pageRoutes.filter(
        (v: any) => v.lang === appStore.language && v.url === appStore.currentPageId,
      );
      if (currentPage && currentPage[0]) {
        const newPage = appStore.pageRoutes.filter(
          (v: any) => v.lang === currentLanguage && v.id === currentPage[0]?.id,
        );
        if (newPage) {
          path = `${newPage[0].lang}${newPage[0].path}${newPage[0].url}`;
        } else {
        }
      } else {
        path = '/';
      }
      setLanguage(currentLanguage);
      localStorage.setItem('language', currentLanguage);
      navigate(path);

      //.map((v: any) => { });
    };

    if (appStore.language && appStore.pageRoutes && currentLanguage) {
      getUrl();
    }
  }, [currentLanguage]);

  return (
    <div className="language">
      {appStore &&
        appStore.languages.map((lang: any, i: number) => (
          <div
            key={i}
            className={`language-item ${lang.iso === appStore.language ? 'selected' : ''}`}
            onClick={() => {
              lang.iso !== appStore.language && changeLanguage(lang.iso);
            }}
          >
            {lang.iso.toUpperCase().replace('EL', 'ΕΛ')}
          </div>
        ))}
    </div>
  );
};

import React from 'react';
import {assetsPath} from '../../../../utils/utils';
import {Image} from '../../../../components/Image';
import './Biologikes.scss';

interface ICategory {
  item: any;
}

export const Biologikes = (props: ICategory) => {
  const {item} = props;
  return (
    <>
      {item.flatData.type === 'biologikes' && (
        <div
          id={item.flatData.friendlyUrl}
          className={`categorySection-texts ${item.flatData.friendlyUrl}`}
          style={{
            backgroundImage:
              item.flatData.backgroundImage !== null && item.flatData.backgroundImage[0]
                ? `url(${assetsPath}/${item.flatData?.backgroundImage[0].slug}`
                : 'none',
          }}
        >
          <div className="categorySection-inner">
            <div className="categorySection-inner-title">{item.flatData.title}</div>
            <div className="categorySection-inner-text" dangerouslySetInnerHTML={{__html: item.flatData.text}} />
            {item.flatData.icon && (
              <div className="categorySection-inner-icon">
                <Image imageClass="" config="cache=2630000" imageUrl={item.flatData.icon[0].flatData.icon[0].slug} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

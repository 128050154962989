import axios from 'axios';
import {useEffect, useState} from 'react';
import config from '../../../config/config.json';
import {Image} from '../../../components/Image/Image';
// import {SocialLinks} from './SocialLinks';
import {SetLink} from '../../SetLink/SetLink';

import './Instagram.scss';

interface IIstagram {
  token: string;
  social: any;
}
export const Instagram = (props: IIstagram) => {
  const {token, social} = props;
  const [instaFeed, setInstaFeed] = useState<any[]>();
  const limit = 6;

  useEffect(() => {
    const getInstaFeed = async () => {
      await axios(`${config.instagramFeedUrl}&limit=${limit}&access_token=${token}`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response: any) => {
          setInstaFeed(response.data.data);
          return response;
        })
        .catch((error: any) => {
          return error;
        });
    };
    if (token) {
      getInstaFeed();
    }
  }, [token]);
  return (
    <div className="instagramFeed">
      {instaFeed && (
        <>
          <div className="instagramItem instaConnect">
            <div className="follow">
              <strong>{social.title}</strong> {social.subtitle}
            </div>
            <div className="social">
              {social.items.iv.map((item: any, index: number) => (
                <SetLink key={index} link={item.itemUrl} title={item.title} friendlyUrl={item[0]?.data.friendlyUrl}>
                  <Image imageUrl={item.itemImage[0].slug} config="" imageAlt={item.itemTitle} />
                </SetLink>
              ))}
            </div>
          </div>
          {instaFeed.map((item: any, index: number) => (
            <a
              className="instagramItem instagramImage"
              key={index}
              href={item.permalink}
              target="_blank"
              rel="noreferrer"
              title={item.caption}
              aria-label={item.itemTitle}
            >
              <img
                src={
                  item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM'
                    ? item.media_url
                    : item.thumbnail_url
                }
                alt={item.caption}
              />{' '}
            </a>
          ))}
        </>
      )}
    </div>
  );
};

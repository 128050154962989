import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Link, useLocation} from 'react-router-dom';
import {$appStore} from '../../store/app/store';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';
import {articlesGraphQl} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';
import {MediaNav} from '../../components/MediaNav/MediaNav';
import {Image} from '../../components/Image/Image';

import './News.scss';

interface INews {
  slug: string;
  id?: string;
  lang?: string;
}

export const News = (props: INews) => {
  const {slug, id, lang} = props;
  const appStore = useStore($appStore);
  const location = useLocation();
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${articlesGraphQl.replace('#LANG#', appStore.language)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryNewsContents);
      setTitle(data.data.queryNewsContents[0].flatData.pageTitle);
      setDescription(data.data.queryNewsContents[0].flatData.pageDescription);
      setLdJson(data.data.queryNewsContents[0].flatData.pageLdJson);
    };
    if (slug) getPage(slug);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) setCurrentPageId(slug);
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);

  return (
    <>
      <MediaNav selected={slug} />

      {pageData && pageData[0] && (
        <div className="news">
          <div className="news-title">{pageData[0].flatData.title}</div>
          <div className="news-subtitle">{pageData[0].flatData.subtitle}</div>
          <div className="news-list">
            {pageData[0].flatData.articles &&
              pageData[0].flatData.articles
                .filter((e: any) => e.flatData.isActive)
                .map((item: any, index: number) => (
                  <Link
                    to={`/${appStore.language}/${slug}/${item.flatData.friendlyUrl}`}
                    className={`news-list-item ${item.flatData.articleStyle.toLowerCase()}`}
                    key={index}
                  >
                    {item.flatData.articleImage[0] && (
                      <div className="news-list-item-imageContainer">
                        <div className="news-list-item-imageContainer-image">
                          <Image
                            imageUrl={item.flatData.articleImage[0].slug}
                            imageAlt={item.flatData.articleTitle}
                            config={'width=900&cache=2630000'}
                          />
                        </div>
                      </div>
                    )}
                    <div className="news-list-item-texts">
                      <div className="news-list-item-texts-title">{item.flatData.articleTitle}</div>
                      <div className="news-list-item-texts-subtitle">{item.flatData.articleSubtitle}</div>
                      <div
                        className="news-list-item-texts-text"
                        dangerouslySetInnerHTML={{__html: item.flatData.articleShortDescription}}
                      />
                      <div className="news-list-item-texts-link">
                        <Image
                          imageUrl="arrow.svg"
                          imageAlt={item.flatData.articleTitle}
                          config={'width=55&cache=2630000'}
                          imageWidth={'55px'}
                        />
                      </div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      )}
    </>
  );
};

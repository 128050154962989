import config from '../config/config.json';

export const sanitizeUppercase = (string: string) => {
  let sanitizedString = '';

  for (let i = 0; i < string.length; i++) {
    sanitizedString += string[i]
      .replace(/ά|Ά/g, 'α')
      .replace(/έ|Έ/g, 'ε')
      .replace(/ή|Ή/g, 'η')
      .replace(/ί|Ί/g, 'ι')
      .replace(/ό|Ό/g, 'ο')
      .replace(/ύ|Ύ/g, 'υ')
      .replace(/ώ|Ώ/g, 'ω')
      .replace(/ς/g, 'σ');
  }

  return sanitizedString.toUpperCase();
};

export const assetsPath = `${config.squidex.url}/${config.squidex.assetsUrl}`;

export const getUrl = (lang: string, typeName: string, friendlyUrl: string) => {
  const path = config.paths.filter((item: any) => item.typeName === typeName.toLowerCase());
  const key = Object.fromEntries(Object.entries(path[0].path).filter(([key]) => key.includes(lang)));
  return `/${lang}${key[lang]}${friendlyUrl}`;
};

export const shuffleArray = (array: any[], all: boolean) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return all ? array : array[0];
};

import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import {CONFIG, getContent} from '../../service/httpService';
import $appStore from '../../store/app/store';
import {Header} from './components/Header';
import {Footer} from './components/Footer';
import {settingsGraphQL, navigationGraphQL} from '../../service/graphQL';
import {Loader} from '../Loader/Loader';
import {setMediaNavItems, setNutritionText, setIsMobile, setGtm, setEnableEnglish} from '../../store/app/events';
import {ModalMedia} from './components/ModalMedia';
import {ModalProduct} from './components/ModalProduct';
import useWindowSize from '../../hooks/useWindowSize';

import {Version} from '../Version/Version';

import './Layout.scss';

const Layout = () => {
  const appStore = useStore($appStore);
  const [headerNavData, setHeaderNavData] = useState<any[]>([]);
  const [footerNavData, setFooterNavData] = useState<any[]>([]);
  const [legalNavData, setLegalNavData] = useState<any[]>([]);
  const [settings, setSettings] = useState<any>();
  const windowSize = useWindowSize();

  useEffect(() => {
    const getSettings = async () => {
      const query = `
   {
     ${settingsGraphQL}
    ${navigationGraphQL}
    }
`;

      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, '');
      setHeaderNavData(data.data.queryNavigationContents[0].flatData.headerNavItems);
      setFooterNavData(data.data.queryNavigationContents[0].flatData.footerNavItems);
      setLegalNavData(data.data.queryNavigationContents[0].flatData.legalNavItems);
      setMediaNavItems(data.data.queryNavigationContents[0].flatData.mediaNavItems);
      setSettings(data.data.querySettingsContents[0].data);
      setEnableEnglish(data.data.querySettingsContents[0].data.english.iv);
      if (!data.data.querySettingsContents[0].data.english.iv) {
        localStorage.setItem('language', 'el');
      }
      setNutritionText(data.data.querySettingsContents[0].data.productNutritionalText[appStore.language]);
      if (data) setGtm(data.data.querySettingsContents[0].data.googleTagManager.iv);
    };

    getSettings();
  }, [appStore.language]);

  useEffect(() => {
    windowSize.width > 1024 ? setIsMobile(false) : setIsMobile(true);
  }, [windowSize.width]);

  useEffect(() => {
    appStore.navIsOpen || appStore.modalImage || appStore.modalProduct
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'scroll');
  }, [appStore.navIsOpen, appStore.modalImage, appStore.modalProduct]);

  return (
    <div className={`layout  ${appStore.isLoading ? 'loading' : ''} ${appStore.isMobile ? 'isMobile' : ''}`}>
      <Header settings={settings} headerNavData={headerNavData} />
      <div className={`main ${appStore.currentPageId}`}>
        <Outlet />
      </div>
      <Footer settings={settings} footerNavData={footerNavData} legalNavData={legalNavData} />
      {appStore.isLoading && <Loader />}
      <ModalMedia />
      <ModalProduct />
      {/* <Version /> */}
    </div>
  );
};

export default Layout;

import {useState, useEffect} from 'react';
import {setIsLoading} from '../../store/app/events';
import {assetsPath} from '../../utils/utils';
import './Image.scss';

interface IImage {
  imageUrl: string;
  imageClass?: string;
  imageAlt?: string;
  config?: string;
  position?: 'absolute' | 'relative';
  excludePath?: boolean;
  download?: boolean;
  imageWidth?: string;
}
export const Image = (props: IImage) => {
  const {imageUrl, imageClass, imageAlt, config, position, excludePath, imageWidth} = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [img, setImg] = useState('');
  const image = excludePath ? imageUrl : `${assetsPath}/${imageUrl}${config ? `?${config}` : ''}`;
  const width = imageWidth ? imageWidth : '100%';
  useEffect(() => {
    setIsLoaded(false);
    setImg(imageUrl);
  }, [imageUrl]);

  return (
    <>
      {img && (
        <img
          src={image}
          className={`image ${imageClass ? imageClass : ''} ${isLoaded ? `loaded` : 'loadingImage'}`}
          alt={imageAlt}
          title={imageAlt}
          style={{position: position, width: width}}
          onLoad={() => {
            setIsLoaded(true);
          }}
        />
      )}
      {/* {!hideThumb && (
        <img
          src={thumb}
          className={`${imageClass && imageClass} ${isLoaded ? 'hideThumb' : 'loadingImage'}`}
          alt={imageAlt}
          title={imageAlt}
          style={{position: position ? position : 'inherit'}}
        />
      )}

      <img
        src={normalImage}
        className={`${imageClass && imageClass} ${isLoaded ? 'isLoaded' : 'notLoaded'}`}
        alt={imageAlt}
        title={imageAlt}
        style={{position: position ? position : 'inherit'}}
        onLoad={() => {
          setIsLoaded(true);
          loadImage();
        }}
      /> */}
    </>
  );
};

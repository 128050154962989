import {useState, useEffect} from 'react';
import {useStore} from 'effector-react';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import {Navigation} from 'swiper';
import {tipsPageGraphQL, tipsGraphQL} from '../../service/graphQL';
import {getContent, CONFIG} from '../../service/httpService';
import {setTitle, setDescription, setLdJson, setCurrentPageId, setIsLoading} from '../../store/app/events';
import $appStore from '../../store/app/store';
import {shuffleArray} from '../../utils/utils';
import {Image} from '../../components/Image';
import useWindowSize from '../../hooks/useWindowSize';
import 'swiper/css';
import 'swiper/css/navigation';

import './Tips.scss';

interface ITips {
  slug: string;
  id: string;
}
export const Tips = (props: ITips) => {
  const {slug, id} = props;
  const appStore = useStore($appStore);
  const size = useWindowSize();
  const [pageData, setPageData] = useState<any>();
  const [tips, setTips] = useState<any>();

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${tipsPageGraphQL}
  ${tipsGraphQL.replace('#CURRENT_LANGUAGE#', appStore.language)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data);
      setTitle(data.data.queryTipspageContents[0].flatData.pageTitle);
      setDescription(data.data.queryTipspageContents[0].flatData.pageDescription);
      setLdJson(data.data.queryTipspageContents[0].flatData.pageLdJson);
      if (data.data.queryTipsContents) {
        setTips(shuffleArray(data.data.queryTipsContents, true));
      }
    };
    if (slug) getPage(slug);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (slug) setCurrentPageId(slug);
  }, [slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);
  return (
    <div className="tipsPage">
      {pageData && tips && (
        <div className="tipsPage-inner">
          <div className="tipsPage-inner-title">{pageData.queryTipspageContents[0].flatData.title}</div>
          <div className="tipsPage-inner-subtitle">{pageData.queryTipspageContents[0].flatData.subtitle}</div>
          <Swiper
            spaceBetween={50}
            autoHeight={true}
            slidesPerView={1}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            className="tipsPage-inner-tipList"
            modules={[Navigation]}
            grabCursor={true}
            navigation={true}
          >
            {tips.map((tip: any, index: number) => (
              <SwiperSlide className="tipsPage-inner-tipList-item" key={index}>
                <div className="tip-title">{tip.flatData.title}</div>
                <div className="tip-text">{tip.flatData.text}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {pageData && (
        <div className="tipsPage-image">
          <Image
            imageUrl={pageData.queryTipspageContents[0].flatData.image[0].slug}
            imageAlt={pageData.queryTipspageContents[0].flatData.title}
            config={`cache=2630000&width=${size.width}&quality=90`}
          />
        </div>
      )}
    </div>
  );
};

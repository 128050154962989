import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {$appStore} from '../../store/app/store';
import {pagesGraphQL} from '../../service/graphQL';
import {CONFIG, getContent} from '../../service/httpService';
import {HeaderImage} from '../../components/HeaderImage/HeaderImage';

import {FooterBand} from '../../components/FooterBand/FooterBand';
import {setCurrentPageId, setDescription, setIsLoading, setLdJson, setTitle} from '../../store/app/events';

import {PagesContent} from './PagesContent';
import {ContactForm} from './ContactForm';

import './Pages.scss';

interface IPage {
  slug: string;
  id?: string;
  lang?: string;
}

export const Pages = (props: IPage) => {
  const {slug, id, lang} = props;
  const appStore = useStore($appStore);
  // const {slug} = useParams();
  const [pageData, setPageData] = useState<any>();
  const [pageId, setPageId] = useState<string>('');

  useEffect(() => {
    const getPage = async (slug: string) => {
      const query = `{
  ${pagesGraphQL.replace('#CURRENT_LANGUAGE#', appStore.language).replace('#SLUG#', slug)}
  }`;
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, appStore.language);
      setPageData(data.data.queryPagesContents[0]?.flatData);
      setCurrentPageId(data.data.queryPagesContents[0]?.flatData.friendlyUrl);
      setTitle(data.data.queryPagesContents[0]?.flatData.pageTitle);
      setDescription(data.data.queryPagesContents[0]?.flatData.pageDescription);
      setLdJson(data.data.queryPagesContents[0]?.flatData.pageLdJson);
    };
    if (slug && appStore.language) getPage(slug);
  }, [appStore.language, slug]);

  useEffect(() => {
    if (pageData) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData]);
  return (
    <>
      {pageData && (
        <div className={`pageContent ${pageData.isContact ? 'contactPage' : ''}`}>
          {pageData.pageHeaderImage[0] && (
            <HeaderImage
              image={`${pageData.pageHeaderImage[0].slug}`}
              title={pageData.title}
              className="pageContent-headerImage"
            />
          )}
          {!pageData.isContact ? (
            <PagesContent pageData={pageData} />
          ) : (
            <>
              {pageData.isContact && (
                <>
                  <h3>{pageData.title}</h3>
                  {pageData.subtitle && <h2>{pageData.subtitle}</h2>}
                  <div className="pageContent-container">
                    <ContactForm />
                    <PagesContent pageData={pageData} />
                  </div>
                </>
              )}
            </>
          )}
          {pageData.pageFooter && <FooterBand text={pageData.pageFooter} />}
        </div>
      )}
    </>
  );
};

import {HelmetProvider} from 'react-helmet-async';
import ScrollToTop from './utils/ScrollToTop';
import {AllRoutes} from './routes/AllRoutes';
import {Seo} from './components/Seo/Seo';
import useCacheBuster from './hooks/useCacheBuster';
import config from './config/config.json';
import {CONFIG, getContent} from './service/httpService';

import './styles/main.scss';
import {useStore} from 'effector-react';
import $appStore from './store/app/store';
import {useEffect, useState} from 'react';
import {setLanguages, setPageRoutes, setLanguage} from './store/app/events';
import {allRoutes} from './service/graphQL';
import {useLocation} from 'react-router-dom';
function App() {
  const appStore = useStore($appStore);
  const cacheBuster = useCacheBuster();
  const [routes, setRoutes] = useState<any>();

  const location = useLocation();

  useEffect(() => {
    const pathArr = location.pathname.split('/');
    if (pathArr[1] === 'en') {
      setLanguage('en');
    } else {
      setLanguage('el');
    }
  }, [location.pathname]);

  useEffect(() => {
    const getlanguages = async () => {
      const langArray: any[] = [];
      const data: any = await getContent('GET', `api/apps/${CONFIG.appName}/languages`, '', '');
      // console.log(data.items.iso2Code);
      data.items.map((lang: any) => langArray.push({iso: lang.iso2Code, name: lang.englishName}));
      setLanguages(langArray);
    };

    getlanguages();
  }, []);

  useEffect(() => {
    const query = `{
    ${allRoutes}
    }`;
    const getUrl = async () => {
      const data: any = await getContent('POST', `api/content/${CONFIG.appName}/graphql`, {query}, '');
      setRoutes(data.data);
    };

    getUrl();
  }, []);

  useEffect(() => {
    const getPages = () => {
      let urls: any[] = [];
      config.paths.map((item) => {
        const path: any = item.path;
        routes[item.query].map((page: any) => {
          appStore.languages.map((lang: any) => {
            if (page.data.friendlyUrl[lang.iso]) {
              const exists = urls.filter((v: any) => v.lang === lang.iso && v.url === page.data.friendlyUrl[lang.iso]);
              if (exists.length > 0) {
                const data: any = getContent(
                  'GET',
                  `api/content/${CONFIG.appName}/${item.name}/${page.id}`,
                  '',
                  '',
                ).then((res: any) => {
                  // console.log(res.data.friendlyUrl[lang.iso]);
                  res.data.friendlyUrl[lang.iso] = `${page.data.friendlyUrl[lang.iso]}-${exists.length}`;
                  const postData: any = getContent(
                    'PUT',
                    `api/content/${CONFIG.appName}/${item.name}/${page.id}`,
                    res.data,
                    lang.iso,
                  );
                  urls.push({
                    type: item.name,
                    id: page.id,
                    path: path[lang.iso],
                    lang: lang.iso,
                    url: `${page.data.friendlyUrl[lang.iso]}-${exists.length}`,
                  });
                });
              } else {
                urls.push({
                  type: item.name,
                  id: page.id,
                  path: path[lang.iso],
                  lang: lang.iso,
                  url: page.data.friendlyUrl[lang.iso],
                });
              }
            }
          });
        });
      });

      return urls;
    };
    if (appStore.languages && appStore.languages.length > 0 && routes) {
      setPageRoutes(getPages());
    }
  }, [appStore.languages, routes]);

  return (
    <HelmetProvider>
      <div className="App">
        <Seo />
        <ScrollToTop />
        {routes && appStore.pageRoutes && appStore.pageRoutes.length > 0 && <AllRoutes routes={routes} />}
      </div>
    </HelmetProvider>
  );
}

export default App;

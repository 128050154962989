import {Image} from '../../../components/Image';
import {SetLink} from '../../../components/SetLink/SetLink';
import {assetsPath} from '../../../utils/utils';
import './SectionNearaFylla.scss';

interface ISectionNearaFylla {
  title: string;
  subtitle: string;
  linkText: string;
  link: string;
  image: string;
  url: any;
}
export const SectionNearaFylla = (props: ISectionNearaFylla) => {
  const {title, subtitle, linkText, link, image, url} = props;
  return (
    <div className="nearaFylla">
      <div
        className="nearaFylla-header"
        style={{backgroundImage: `url(${assetsPath}/welovesalads-header-bg.png?cache=2630000)`}}
      >
        <h2>{title}</h2>
      </div>
      <h4>{subtitle}</h4>
      <SetLink className="nearaFylla-image" link={link} title={linkText} friendlyUrl={url[0].flatData.friendlyUrl}>
        <Image imageUrl={image} config="width=760&cache=2630000" imageAlt={title} />
      </SetLink>
      <SetLink className="nearaFylla-link" link={link} title={linkText} friendlyUrl={url[0].flatData.friendlyUrl}>
        {linkText}
        <Image imageUrl={`arrow.svg`} config="cache=2630000" imageAlt={linkText} />
      </SetLink>
    </div>
  );
};

import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import {sanitizeUppercase} from '../../../utils/utils';
import {scrollTo} from '../../../utils/scrollTo';

import './Navigation.scss';
interface INavigation {
  subCategories: any;
  parent: string;
}
export const Navigation = (props: INavigation) => {
  const appStore = useStore($appStore);
  const {subCategories, parent} = props;

  const scrollNav = (url: string) => {
    setTimeout(() => {
      const element = document.getElementById(url);
      // window.history.replaceState(null, '', `/${appStore.language}/${parent}/${url}`);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', inline: 'start'});
        document.body.style.overflow = 'auto';
      }
    }, 0);
  };

  return (
    <>
      {subCategories.length > 0 && (
        <div className="category-navigation">
          {subCategories.map((item: any, index: number) => (
            <div
              className="category-navigation-item"
              key={index}
              onClick={() => scrollTo({id: item.flatData.friendlyUrl})}
            >
              {sanitizeUppercase(item.flatData.categoryName)}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
